import React from "react";

const MasterCard = () => {
  return (
    <svg
      width="39"
      height="24"
      viewBox="0 0 39 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3957_2422)">
        <path
          d="M24.6367 2.56689H14.1357V21.4329H24.6367V2.56689Z"
          fill="#FF5A00"
        />
        <path
          d="M14.835 12C14.835 8.16699 16.6369 4.76505 19.4019 2.56699C17.367 0.96699 14.8 0 12 0C5.36699 0 0 5.36699 0 12C0 18.633 5.36699 24 12 24C14.8 24 17.367 23.033 19.4019 21.433C16.633 19.266 14.835 15.833 14.835 12Z"
          fill="#EB001B"
        />
        <path
          d="M38.8033 12C38.8033 18.633 33.4363 24 26.8033 24C24.0033 24 21.4363 23.033 19.4014 21.433C22.2014 19.2311 23.9684 15.833 23.9684 12C23.9684 8.16699 22.1664 4.76505 19.4014 2.56699C21.4324 0.96699 23.9994 0 26.7994 0C33.4363 0 38.8033 5.40194 38.8033 12Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_3957_2422">
          <rect width="38.835" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MasterCard;
