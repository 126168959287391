import React from "react";

const Calendar = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15C7.55228 15 8 14.5523 8 14C8 13.4477 7.55228 13 7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15Z"
        fill="#AAAAAA"
      />
      <path
        d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z"
        fill="#AAAAAA"
      />
      <path
        d="M10.3496 15C10.9019 15 11.3496 14.5523 11.3496 14C11.3496 13.4477 10.9019 13 10.3496 13C9.79733 13 9.34961 13.4477 9.34961 14C9.34961 14.5523 9.79733 15 10.3496 15Z"
        fill="#AAAAAA"
      />
      <path
        d="M11.3496 17C11.3496 17.5523 10.9019 18 10.3496 18C9.79733 18 9.34961 17.5523 9.34961 17C9.34961 16.4477 9.79733 16 10.3496 16C10.9019 16 11.3496 16.4477 11.3496 17Z"
        fill="#AAAAAA"
      />
      <path
        d="M13.6504 15C14.2027 15 14.6504 14.5523 14.6504 14C14.6504 13.4477 14.2027 13 13.6504 13C13.0981 13 12.6504 13.4477 12.6504 14C12.6504 14.5523 13.0981 15 13.6504 15Z"
        fill="#AAAAAA"
      />
      <path
        d="M14.6504 17C14.6504 17.5523 14.2027 18 13.6504 18C13.0981 18 12.6504 17.5523 12.6504 17C12.6504 16.4477 13.0981 16 13.6504 16C14.2027 16 14.6504 16.4477 14.6504 17Z"
        fill="#AAAAAA"
      />
      <path
        d="M17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13C16.4477 13 16 13.4477 16 14C16 14.5523 16.4477 15 17 15Z"
        fill="#AAAAAA"
      />
      <path
        d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z"
        fill="#AAAAAA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 2.5C8.75 2.08579 8.41421 1.75 8 1.75C7.58579 1.75 7.25 2.08579 7.25 2.5V4.25H6C3.92893 4.25 2.25 5.92893 2.25 8V18C2.25 20.0711 3.92893 21.75 6 21.75H18C20.0711 21.75 21.75 20.0711 21.75 18V8C21.75 5.92893 20.0711 4.25 18 4.25H16.75V2.5C16.75 2.08579 16.4142 1.75 16 1.75C15.5858 1.75 15.25 2.08579 15.25 2.5V4.25H8.75V2.5ZM20.25 9.25V8C20.25 6.75736 19.2426 5.75 18 5.75H6C4.75736 5.75 3.75 6.75736 3.75 8V9.25H20.25ZM3.75 10.75H20.25V18C20.25 19.2426 19.2426 20.25 18 20.25H6C4.75736 20.25 3.75 19.2426 3.75 18V10.75Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default Calendar;
