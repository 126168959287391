import React from "react";
import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import style from "./styles.module.scss";
import NewsIcon from "../../icons/NewsIcon";
import DocumentationIcon from "../../icons/DocumentationIcon";
import ManagerIcon from "../../icons/ManagerIcon";
import TGChannelIcon from "../../icons/TGChannelIcon";

// Footer manuBar
const Footer = () => {
  return (
    <footer className={style.footer}>
      <div>
        <Link to={PAGE_ROUTES.NEWS} className={style.containerFooterItem}>
          <NewsIcon fill="#222222" />
          Latest news
        </Link>
      </div>

      <div>
        <Link
          target="_blank"
          to="https://docs.fbm-pay.com/"
          className={style.containerFooterItem}
        >
          <DocumentationIcon />
          Documentation
        </Link>
      </div>

      <div>
        <Link
          target="_blank"
          to={PAGE_ROUTES.YOUR_MANAGER}
          className={style.containerFooterItem}
        >
          <ManagerIcon />
          Manager
        </Link>
      </div>

      <div className={style.containerTgChannel}>
        <Link
          target="_blank"
          to={PAGE_ROUTES.TG_CHANNEL}
          className={style.containerLinksFooter}
        >
          <TGChannelIcon />
          Our TG channel
        </Link>
        <Link
          target="_blank"
          className={style.subscripteLink}
          to={PAGE_ROUTES.TG_CHANNEL}
        >
          Subscribe
        </Link>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
