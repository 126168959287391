import React, { useCallback, useEffect } from "react";
import Modal from "../Modal";
import { Button } from "../../../components/Button/Button";
import styles from "./styles.module.scss";
import ReloadIcon from "../../../icons/ReloadIcon";

const PaymentBeingProcessedModal = ({
  setProcessedModalShow,

  processedModalType,
}) => {
  const handleCloseModal = useCallback(() => setProcessedModalShow(false), []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleCloseModal();
    }, 6000);

    return () => clearTimeout(timer);
  }, [handleCloseModal]);

  return (
    <Modal>
      <div className={styles.containerPaymentProcessedModal}>
        <div className={styles.containerTItleText}>
          <div className={styles.containerHeaderTitle}>
            <h2 className={styles.titleModal}>
              Your payment is being processed
            </h2>
            <ReloadIcon
              style={{
                animation: "spin 2s linear infinite",
                height: "23px",
              }}
            />
          </div>

          <p className={styles.paragraphTitle}>
            If the funds have been sent, they will be credited to your account{" "}
            <span className={styles.minutesWithinTitle}>
              within {processedModalType === "transfer" ? "1" : "10"}{" "}
              {processedModalType === "transfer" ? "minute" : "minutes"}
            </span>
            . After transaction completed you can view it in your{" "}
            <strong>balance history</strong>.
          </p>
        </div>

        <div className={styles.containerCloseBtn}>
          <Button onClick={handleCloseModal}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentBeingProcessedModal;
