import { useState, useEffect, useContext } from "react";
import { Button, Group, Box } from "@mantine/core";
import Table from "../../components/Table/Table";
import { getAllTeamMembers } from "../../api/usersApi";
import { IconPlus } from "@tabler/icons-react";
import ModalAddMember from "../Modals/ModalAddMember/ModalAddMember";
import { ModalsInteractive } from "../Modals/TeamManagementModals/ModalsInteractive";
import { useQueryClient } from "@tanstack/react-query";
import { useTeamManagementHandlers } from "../../hooks/handles/useTeamManagementHandlers";
import ColumnsTeamManagement from "../../constants/columns/ColumnsTeamManagement";
import styles from "./styles.module.scss";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import ReloadIcon from "../../icons/ReloadIcon";
import { optionsTable } from "../../constants/constants";
import { AuthContext } from "../../context/AuthContext";
import useIsMobile from "../../hooks/useIsMobile";

const TeamManagementTable = () => {
  const pageSize = 15;

  const queryClient = useQueryClient();

  const [data, setData] = useState([]);
  const [modals, setModals] = useState({
    modalAddCurrentMember: false,
    modalAddMember: false,
  });
  const [modalData, setModalData] = useState(null);
  const [activeModalInveractive, setActiveModalInveractive] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
  });
  const { userData } = useContext(AuthContext);
  const {
    data: tableData,
    isLoading,
    isFetching,
  } = useAuthQuery({
    queryKey: ["tableData", pagination.pageIndex, pageSize],
    queryFn: () =>
      getAllTeamMembers({
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
    keepPreviousData: true,
    staleTime: 2 * 60 * 1000,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: ["tableData", pagination.pageIndex + 2, pageSize],
          queryFn: () =>
            getAllTeamMembers({
              page: pagination.pageIndex + 2,
              perPage: pageSize,
            }),
        });
      }
    },
  });
  const isMobile = useIsMobile();

  useEffect(() => {
    if (tableData?.data && !isLoading && !isFetching) {
      setData(tableData.data.data);
    }
  }, [tableData, isLoading, isFetching]);

  const { handleEditUser, handleOpenModal, handleAddUser, handleModalSubmit } =
    useTeamManagementHandlers({
      setData,
      setActiveModalInveractive,
      setModalData,
      setIsEditModal,
      setModals,
      queryClient,
    });

  useEffect(() => {
    const bodyRoot = document.getElementById("root");

    if (modals.modalAddMember) {
      bodyRoot.style.overflow = "hidden";
    } else {
      bodyRoot.style.overflow = "auto";
    }
  }, [modals.modalAddMember]);

  if (isLoading)
    return <Skeleton className={styles.skeletonTable} isSpinner={true} />;

  return (
    <Box
      style={{ borderRadius: "8px", overflow: "hidden", background: "#fff" }}
      styles={{
        maxWidth: "calc(100% - 40px)",
        minWidth: "700px",
        background: "red",
      }}
    >
      <div className={styles.containerReloadTable}>
        <Button
          onClick={handleAddUser}
          rightIcon={<IconPlus />}
          style={{
            background:
              "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
            color: "#fff",
            border: "none",
            borderRadius: "8px",
            marginRight: "16px",
          }}
        >
          Add member
        </Button>
        <ReloadIcon
          onClick={() => queryClient.invalidateQueries(["tableData"])}
          style={{
            animation: isFetching ? "spin 1s linear infinite" : "none",
            cursor: "pointer",
          }}
        />
      </div>
      <Table
        columns={ColumnsTeamManagement({
          handleEditUser,
          handleOpenModal,
          userData,
          isMobile,
        })}
        data={data}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={(updater) => {
          setPagination((prev) => {
            const newState =
              typeof updater === "function" ? updater(prev) : updater;
            return newState;
          });
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pageSize,
          },
        }}
        enableColumnActions={false}
        rowCount={tableData?.data?.meta?.total ?? 0}
        pageCount={
          tableData?.data?.meta?.last_page ??
          Math.ceil((tableData?.data?.meta?.total ?? 0) / pageSize)
        }
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableTopToolbar={false}
        enableDensityToggle={false}
        paginationDisplayMode={"pages"}
        mantinePaginationProps={{
          showRowsPerPage: false,
          showFirstLastButtons: true,
        }}
        mantineTableProps={{
          ...optionsTable,
        }}
      />

      <ModalAddMember
        setModals={setModals}
        modals={modals}
        isEdit={isEditModal}
        userData={modalData}
        setData={setData}
      />

      <ModalsInteractive
        activeModal={activeModalInveractive}
        modalData={modalData}
        onSubmit={handleModalSubmit}
        onCancel={() => {
          setActiveModalInveractive(null);
          setModalData(null);
        }}
      />
    </Box>
  );
};

export default TeamManagementTable;
