import React, { useState } from "react";
import styles from "./styles.module.scss";
import ReloadIcon from "../../icons/ReloadIcon";
import MetapayTable from "../Tables/TableMetapayAnd3Ds/MetapayTable";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";

const Metapay = () => {
  const query = useQueryClient();
  const isFetching = useIsFetching(["metapayCodes"]);
  // const [isFiltersShow, setIsFiltersShow] = useState(false);

  // const [filters, setFilters] = useState({
  //   dateFrom: null,
  //   dateTo: null,
  //   iban: null,
  //   ibanId: null,
  // });

  const handleRefetch = () => {
    query.invalidateQueries(["metapayCodes"], { exact: true });
  };

  return (
    <div>
      <div className={styles.containerTitle}>
        <div className={styles.containerWithFilters}>
          <div className={styles.containerSpinner}>
            <h2 className="H3">METAPAY CODES</h2>

            {/* <FiltersButton
              isActive={isFiltersShow}
              onClick={() => setIsFiltersShow((prev) => !prev)}
            /> */}
            <ReloadIcon
              className={styles.reloadIcon}
              onClick={handleRefetch}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
        </div>

        {/* {isFiltersShow && (
          <ThreeDsAndMetapay
            filters={filters}
            setFilters={setFilters}
            queryRequestKey="metapay"
            queryKeyToInvalidate="metapayCodes"
          />
        )} */}
      </div>

      <MetapayTable />
    </div>
  );
};

export default Metapay;
