const PaymentIcon = ({ fill = "#232323" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 8.25C7.83578 8.25 7.5 8.58578 7.5 9C7.5 9.41415 7.83578 9.75 8.25 9.75H15.75C16.1642 9.75 16.5 9.41415 16.5 9C16.5 8.58578 16.1642 8.25 15.75 8.25H8.25Z"
        fill={fill}
      />
      <path
        d="M7.5 12C7.5 11.5858 7.83578 11.25 8.25 11.25H15.75C16.1642 11.25 16.5 11.5858 16.5 12C16.5 12.4141 16.1642 12.75 15.75 12.75H8.25C7.83578 12.75 7.5 12.4141 7.5 12Z"
        fill={fill}
      />
      <path
        d="M12.375 14.25C11.9608 14.25 11.625 14.5858 11.625 15C11.625 15.4141 11.9608 15.75 12.375 15.75H15.75C16.1642 15.75 16.5 15.4141 16.5 15C16.5 14.5858 16.1642 14.25 15.75 14.25H12.375Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7657 21.7087C12.2852 21.9596 11.7121 21.9585 11.2325 21.7057L8.70382 20.3728C8.6592 20.3492 8.6058 20.3497 8.56162 20.374L6.19457 21.6746C5.09494 22.2788 3.75 21.4832 3.75 20.2285V3.76604C3.75 2.5136 5.09059 1.71778 6.1901 2.31752L8.56238 3.61149C8.60633 3.63545 8.65928 3.63593 8.70368 3.61277L11.2362 2.29021C11.7148 2.04031 12.2852 2.04031 12.7638 2.29021L15.2963 3.61277C15.3407 3.63593 15.3937 3.63545 15.4376 3.61149L17.8099 2.31752C18.9094 1.71778 20.25 2.5136 20.25 3.76604V20.2339C20.25 21.4863 18.9094 22.2821 17.8099 21.6824L15.4376 20.3884C15.3937 20.3644 15.3407 20.364 15.2963 20.3872L12.7657 21.7087ZM11.932 20.3788C11.9756 20.4018 12.0277 20.4019 12.0714 20.3791L14.602 19.0576C15.0898 18.8027 15.6727 18.808 16.1559 19.0716L18.5282 20.3656C18.6281 20.4201 18.75 20.3477 18.75 20.2339V3.76604C18.75 3.65218 18.6281 3.57983 18.5282 3.63436L16.1559 4.92833C15.6727 5.1919 15.0898 5.19716 14.602 4.94238L12.0695 3.61982C12.0259 3.59711 11.9741 3.59711 11.9305 3.61982L9.39802 4.94238C8.91015 5.19716 8.32733 5.1919 7.8441 4.92833L5.47183 3.63436C5.37188 3.57983 5.25 3.65218 5.25 3.76604V20.2285C5.25 20.3426 5.37226 20.4149 5.47223 20.36L7.8393 19.0594C8.32523 18.7924 8.91277 18.7873 9.40327 19.0458L11.932 20.3788Z"
        fill={fill}
      />
    </svg>
  );
};

export default PaymentIcon;
