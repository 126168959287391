import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CopyIcon from "../../icons/CopyIcon";
import clsx from "clsx";
import { toast } from "react-toastify";
import McPk from "../../icons/cardsIcons/MasterCardBG.svg";
import VisaPk from "../../icons/cardsIcons/VisaCardBG.svg";
import UnionPayPk from "../../icons/cardsIcons/UnionCardBG.svg";

const CardItem = ({ cardType, cardCredentials }) => {
  const { cardNumber, cvv, expirationDate } = cardCredentials;
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 500);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const getCardSrc = () => {
    switch (cardType) {
      case "visa":
        return VisaPk;
      case "mc":
        return McPk;
      case "union":
        return UnionPayPk;
      default:
        return "";
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCopyAll = () => {
    const textToCopy = `${cardNumber} ${expirationDate} ${cvv}`;
    copyToClipboard(textToCopy);
  };

  const formatCardNumberToSpans = () => {
    return cardNumber
      ? cardNumber
          .match(/.{1,4}/g)
          .map((group, index) => <span key={index}>{group}</span>)
      : "No card information";
  };

  return (
    <div className={clsx(styles.container)}>
      <img src={getCardSrc()} alt="img" />

      <div className={styles.containerCardData}>
        <div className={styles.dataExpNumber}>
          <div className={styles.cardNumber}>
            {formatCardNumberToSpans(cardNumber)}
          </div>
          <CopyIcon
            fill="#CBF6D9"
            onClick={() => copyToClipboard(cardNumber)}
            className={styles.copyIcon}
          />
        </div>

        <div className={styles.containerAddInfo}>
          <div className={styles.dataExp}>
            <span className={styles.commonText}>EXP</span>
            <span className={styles.extraBold}>{expirationDate}</span>
            <CopyIcon
              fill="#CBF6D9"
              onClick={() => copyToClipboard(expirationDate)}
              className={styles.copyIcon}
            />
          </div>

          <div className={styles.dataExp}>
            <span>CVV</span>
            <span className={styles.extraBold}>{cvv}</span>
            <CopyIcon
              fill="#CBF6D9"
              onClick={() => copyToClipboard(cvv)}
              className={styles.copyIcon}
            />
          </div>
        </div>
        <div className={styles.dataExp}>
          <span>COPY ALL</span>

          <CopyIcon
            fill="#CBF6D9"
            onClick={handleCopyAll}
            className={styles.copyIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default CardItem;
