import { useEffect, useState } from "react";
import styles from "./ResetPassword.module.scss";
import { useForm } from "react-hook-form";
import { Button } from "../../components/Button/Button";
import TelegramIcon from "../../icons/TelegramIcon";
import EyeOpened from "../../icons/HidePasswordEye";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  recoverPasswordCode,
  setNewPassword,
} from "../../api/recoverPasswordAuthentication";
import clsx from "clsx";
import { showToast } from "../../modules/Notification/ActionNotification";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(true);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(true);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const user = { token, email };

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
    setError,
  } = useForm({
    password: "",
    password_confirmation: "",
  });

  const { password } = watch();

  useEffect(() => {
    if (errors && errors.serverError) {
      clearErrors("serverError");
    }
  }, [password, errors]);

  const onSubmit = async (password) => {
    try {
      setIsLoading(true);
      const data = { ...user, ...password };

      const response = await setNewPassword(data);

      if (response.success) {
        navigate("/login");
      }
      showToast("success", "Your password has been reset.");
    } catch (error) {
      setError("serverError", { message: error.response });
    } finally {
      setIsLoading(false);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  return (
    <div className={styles.resetPage}>
      <div className={styles.resetPageContainer}>
        <div className={styles.resetPageTitle}>
          <h1>Password change</h1>
          <p className="primaryText">
            Enter a new password that you will use to log in to [FBM] Pay.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.resetPasswordForm}
        >
          <div className={styles.resetPasswordInputs}>
            <div className={styles.resetPasswordInput}>
              <label htmlFor="password" className="primaryText">
                New password
              </label>
              <input
                id="password"
                type={isNewPasswordVisible ? "password" : "text"}
                placeholder="********"
                {...register("password", {
                  required: "New password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              <EyeOpened
                className={styles.loginContainerInputEye}
                isClosed={!isNewPasswordVisible}
                onClick={toggleNewPasswordVisibility}
              />
              {errors.password && (
                <p className={styles.error}>{errors.password.message}</p>
              )}
            </div>

            <div className={styles.resetPasswordInput}>
              <label htmlFor="password_confirmation" className="primaryText">
                Confirm Password
              </label>
              <input
                id="password_confirmation"
                type={isConfirmPasswordVisible ? "password" : "text"}
                placeholder="********"
                {...register("password_confirmation", {
                  required: "Please confirm your password",
                  validate: (value) => {
                    const { password } = getValues();
                    return value === password || "Passwords do not match";
                  },
                })}
              />
              <EyeOpened
                className={styles.loginContainerInputEye}
                isClosed={!isConfirmPasswordVisible}
                onClick={toggleConfirmPasswordVisibility}
              />
              {errors.password_confirmation && (
                <p className={styles.error}>
                  {errors.password_confirmation.message}
                </p>
              )}
            </div>
          </div>
          <Button isLoading={isLoading} type="submit">
            Update password
          </Button>
        </form>

        <div className={styles.loginContainerRegistration}>
          <div className={styles.loginContainerRegistrationText}>
            <p className="semiHedline">Any troubles?</p>
            <p className={clsx("primaryText", styles.primaryText)}>
              Contact your manager{" "}
              <a
                href="https://t.me/v_astanin"
                target="_blank"
                className={styles.loginRecoverLink}
                rel="noreferrer"
              >
                @fbm_ivan
              </a>{" "}
              for help
            </p>
          </div>
          <Button className={styles.loginContainerRegistrationButton}>
            <a
              href="https://t.me/v_astanin"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact <TelegramIcon />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
