import { Navigate, Route, Routes } from "react-router-dom";
import { authRoutes, publicRoutes } from "./pages/routes";
import { PrivateRoute } from "./pages/PrivateRoutes";
import { PAGE_ROUTES } from "./pages/constants";
import MainLayout from "./modules/MainLayout/MainLayout";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { ActionNotification } from "./modules/Notification/ActionNotification";

function App() {
  const { token } = useContext(AuthContext);

  return (
    <>
      <ActionNotification position="top-right" autoClose={2000} />

      <Routes>
        <Route
          path="/ru"
          element={
            token ? (
              <Navigate to={PAGE_ROUTES.DASHBOARD} replace />
            ) : (
              <iframe
                title="landingRu"
                src="/landingRU/index.html"
                style={{ width: "100%", height: "100vh", border: "none" }}
              />
            )
          }
        />

        <Route
          path="/eng"
          element={
            token ? (
              <Navigate to={PAGE_ROUTES.DASHBOARD} replace />
            ) : (
              <iframe
                title="landingEn"
                src="/landingEN/index.html"
                style={{ width: "100%", height: "100vh", border: "none" }}
              />
            )
          }
        />

        <Route
          path="/"
          element={
            token ? (
              <Navigate to={PAGE_ROUTES.DASHBOARD} replace />
            ) : (
              <Navigate to={PAGE_ROUTES.LOGIN} replace />
            )
          }
        />

        <Route element={<MainLayout />}>
          {authRoutes.map(({ path, Element, allowedRoles }) => {
            return (
              <Route
                key={path}
                path={path}
                element={
                  <PrivateRoute allowedRoles={allowedRoles}>
                    {<Element />}
                  </PrivateRoute>
                }
              />
            );
          })}
        </Route>

        {publicRoutes.map(({ path, Element }) => {
          return <Route key={path} path={path} element={<Element />} />;
        })}

        <Route
          path="*"
          element={<Navigate to={PAGE_ROUTES.NOT_FOUND} replace />}
        />
      </Routes>
    </>
  );
}

export default App;
