export const getPaymentSystemLabel = (paymentSystem) => {
  switch (paymentSystem.toLowerCase()) {
    case "visa":
      return "VISA";
    case "mc":
      return "MASTER CARD";
    case "union":
      return "UNION PAY";
    case "up":
      return "UNION PAY";
    default:
      return paymentSystem.toUpperCase();
  }
};
