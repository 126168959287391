const IconEdit = ({ onClick }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        onClick={onClick}
        style={{ cursor: "pointer" }}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="4.8" fill="#232323" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.193 5.6962C16.6031 5.28616 17.2679 5.28616 17.678 5.69621L18.3037 6.32191C18.7137 6.73196 18.7137 7.39679 18.3037 7.80684L12.256 13.8545C12.0029 14.1076 11.6595 14.2499 11.3014 14.2499H10.2C9.95147 14.2499 9.75 14.0484 9.75 13.7999V12.6984C9.75 12.3404 9.89223 11.997 10.1454 11.7438L16.193 5.6962ZM17.0416 6.33261C16.983 6.27403 16.888 6.27403 16.8295 6.33261L16.0848 7.07723L16.9226 7.91505L17.6673 7.17044C17.7259 7.11186 17.7259 7.01688 17.6673 6.95831L17.0416 6.33261ZM16.2863 8.55145L15.4484 7.71362L10.7818 12.3802C10.6974 12.4646 10.65 12.579 10.65 12.6984V13.3499H11.3014C11.4208 13.3499 11.5352 13.3024 11.6197 13.218L16.2863 8.55145Z"
          fill="white"
        />
        <path
          d="M8.69941 7.0499C7.78815 7.0499 7.04941 7.78863 7.04941 8.6999V15.2999C7.04941 16.2112 7.78815 16.9499 8.69941 16.9499H15.2994C16.2107 16.9499 16.9494 16.2112 16.9494 15.2999V11.9999C16.9494 11.7514 17.1509 11.5499 17.3994 11.5499C17.6479 11.5499 17.8494 11.7514 17.8494 11.9999V15.2999C17.8494 16.7082 16.7077 17.8499 15.2994 17.8499H8.69941C7.29109 17.8499 6.14941 16.7082 6.14941 15.2999V8.6999C6.14941 7.29158 7.29109 6.1499 8.69941 6.1499H11.9994C12.2479 6.1499 12.4494 6.35138 12.4494 6.5999C12.4494 6.84843 12.2479 7.0499 11.9994 7.0499H8.69941Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default IconEdit;
