import { Button, Checkbox, Group, Popover, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import React, { useState } from "react";
import styles from "./styles.module.scss";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import QuestionIcons from "../../icons/QuestionIcons";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { showToast } from "../../modules/Notification/ActionNotification";
import { getCardStatus } from "../../utils/getCardStatus";
import { widthStatusTitle } from "../../styles/constants";
import { getUserRolePriority } from "../../utils/getUserPriority";
import { getCorrectCardType } from "../../utils/getCorrectCardType";
import CardNumber, {
  CardNoInfoText,
} from "../../components/CardNumber/CardNumber";

const ColumnsCards = ({
  selectedRows,
  setSelectedRows,
  handleOpenModalRemoveUser,
  dataTable,
  userData,
}) => {
  const handleSelectAllChange = () => {
    const validRows = dataTable.filter(
      (row) =>
        row.stateCode !== "50" && row.statusName !== "AWAITING ACTIVATION"
    );

    if (selectedRows.length === validRows.length) {
      setSelectedRows([]);
    } else {
      if (selectedRows.length >= 15) {
        showToast("error", "You can select a maximum of 15 items.");
      } else {
        const newSelected = validRows.slice(0, 15);
        setSelectedRows(newSelected);
      }
    }
  };

  const handleCheckboxChange = (row) => {
    const cardStateCode = row.stateCode === "50";
    const cardStatusName = row.statusName === "AWAITING ACTIVATION";

    if (cardStateCode || cardStatusName) {
      showToast("error", "You cannot do any actions with a Pending card.");

      setSelectedRows((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== row.id)
      );
      return;
    }

    setSelectedRows((prevSelected) => {
      if (prevSelected.some((selected) => selected.id === row.id)) {
        return prevSelected.filter((selected) => selected.id !== row.id);
      }

      if (prevSelected.length >= 15) {
        showToast("error", "You can select a maximum of 15 items.");
        return prevSelected;
      }

      return [...prevSelected, row];
    });
  };

  const columns = [
    {
      id: "selection",
      header: (
        <Checkbox
          checked={
            selectedRows.length ===
            dataTable.filter(
              (row) =>
                row.stateCode !== "50" &&
                row.statusName !== "AWAITING ACTIVATION"
            ).length
          }
          onChange={handleSelectAllChange}
          mb="sm"
          sx={{
            width: "24px",
            height: "24px",
            marginBottom: "0px !important",
            "& .mantine-Checkbox-input:checked": {
              background:
                "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
              border: "none",
              cursor: "pointer",
            },
            "& .mantine-Checkbox-input:not(:checked)": {
              backgroundColor: "#f0f0f0",
              cursor: "pointer",
            },
          }}
        />
      ),
      size: 50,
      Cell: ({ row }) => (
        <Checkbox
          checked={selectedRows.some(
            (selected) => selected.id === row.original.id
          )}
          onChange={() => handleCheckboxChange(row.original)}
          mb="sm"
          sx={{
            width: "24px",
            height: "24px",
            marginBottom: "0px !important",

            "& .mantine-Checkbox-input:checked": {
              background:
                "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
              border: "none",
              cursor: "pointer",
            },
            "& .mantine-Checkbox-input:not(:checked)": {
              backgroundColor: "#f0f0f0",
              cursor: "pointer",
            },
          }}
        />
      ),
    },

    {
      accessorKey: "cardMask",
      header: "CARD NUMBER",
      enableSorting: false,
      Cell: ({ cell, row }) => {
        const cardNum =
          cell.getValue() !== null ? cell.getValue() : CardNoInfoText;
        const paymentSystem = row.original.paymentSystem;
        const cardStateCode = row.original.stateCode === "50";
        const cardStatusName =
          row.original.statusName === "AWAITING ACTIVATION";
        const navigate = useNavigate();
        const cardId = row.original.cardId;

        const handleNavigateToCard = () => {
          if (cardStateCode || cardStatusName) return null;
          navigate(`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`);
        };

        return (
          <div className={styles.containerCardName}>
            {getCorrectCardType(paymentSystem)}
            <CardNumber
              cardStateCode={cardStateCode}
              cardStatusName={cardStatusName}
              onClick={handleNavigateToCard}
              cardNum={cardNum}
            />

            <CustomTooltip
              placement="bottom-start"
              title="Click to see card details "
              arrow
              onClick={handleNavigateToCard}
            >
              <span className={styles.containerQuestion}>
                <QuestionIcons />
              </span>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      accessorKey: "name",
      header: "CARD NAME",
      accessorFn: (row) => (row.name !== null ? row.name : "No name"),
      enableSorting: false,
      Cell: ({ cell, row }) => {
        const navigate = useNavigate();
        const cardId = row.original.cardId;
        const name = cell.getValue() !== null ? cell.getValue() : "No name";
        const cardStateCode = row.original.stateCode === "50";
        const cardStatusName =
          row.original.statusName === "AWAITING ACTIVATION";

        const handleNavigateToCard = () => {
          if (cardStateCode || cardStatusName) return null;

          navigate(`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`);
        };

        return (
          <span
            className={styles.cardName}
            style={{
              whiteSpace: "nowrap",
              cursor:
                cardStateCode || cardStatusName ? "not-allowed" : "pointer",
            }}
            onClick={handleNavigateToCard}
          >
            {name}
          </span>
        );
      },
    },
    {
      accessorKey: "creationDate",
      header: "CREATION DATE",
      enableSorting: false,
      enableColumnFilter: true,
      accessorFn: (row) => row.creationDate,
      Cell: ({ cell }) => {
        const date = cell.getValue();
        return (
          <DateFormatter
            withTime={true}
            date={date}
            className={styles.dateText}
          />
        );
      },
    },
    {
      accessorKey: "status",
      header: "STATUS",
      accessorFn: (row) => row.statusName,
      enableSorting: false,
      Cell: ({ row }) => {
        const statusName = row.original.statusName;
        const statusCode = row.original.statusCode;

        const { statusCard, colorContainer, colorText } = getCardStatus(
          statusName || statusCode
        );

        return (
          <div
            style={{
              background: colorContainer,
              color: colorText,
              width: widthStatusTitle,
              textAlign: "center",
            }}
            className={styles.statusCard}
          >
            {statusCard}
          </div>
        );
      },
    },
    {
      accessorKey: "balance",
      header: "BALANCE",
      accessorFn: (row) => row.balance,
      enableSorting: false,
      Cell: ({ cell }) => (
        <div>
          {cell.getValue() === null ? (
            <span>€ 0</span>
          ) : (
            <span>€ {cell.getValue()}</span>
          )}
        </div>
      ),
    },
    {
      accessorKey: "ibanAcc",
      header: "ACCOUNT #",
      accessorFn: (row) => (row.name !== null ? row.name : "No name"),
      enableSorting: false,
      Cell: ({ row }) => {
        const name = row.original.account.iban.slice(-6);
        return (
          <div className={styles.ibanAcc}>
            <span>#{name}</span>
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              {row.original.account.name}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "usersCards",
      header: "USERS",
      enableSorting: false,

      size: 100,
      Cell: ({ row }) => {
        const [opened, setOpened] = useState(false);

        const accountsCount = row.original.account.usersCount;
        const accounts = row.original.account.users;

        return (
          <Group spacing="sm">
            <Group
              spacing={0}
              style={{
                gap: "0px",
                alignItems: "center",
              }}
            >
              <Text
                size="sm"
                color="green"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  return accountsCount > 0
                    ? setOpened((prev) => !prev)
                    : undefined;
                }}
              >
                {accountsCount}
              </Text>

              <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom-start"
                withArrow
                withinPortal
                styles={{
                  dropdown: {
                    maxWidth: "none",
                    position: "absolute",
                    zIndex: 1000000,
                  },
                }}
                disabled={userData.role === "Member"}
              >
                <Popover.Target>
                  <Button
                    size="xs"
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    variant="subtle"
                    onClick={() => {
                      return accountsCount > 0
                        ? setOpened((prev) => !prev)
                        : undefined;
                    }}
                  >
                    <IconChevronDown size={16} color="green" />
                  </Button>
                </Popover.Target>
                {/* скрывать для мемберов */}
                {userData.role === "Member" ? (
                  ""
                ) : (
                  <Popover.Dropdown>
                    {accounts?.map((account, idx) => {
                      return (
                        <div
                          key={account.id}
                          className={styles.popupTitleBlock}
                        >
                          <Text
                            size="sm"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              gap: "2px",
                            }}
                          >
                            {/* <p className="body1">{account.name || "Unnamed"} </p> */}
                            <p className="body1">
                              {account.email || "No email"}{" "}
                            </p>
                          </Text>
                          {getUserRolePriority(account, userData) && (
                            <Button
                              onClick={() => {
                                handleOpenModalRemoveUser("removeCardUser", {
                                  userName: account.name,
                                  id: row.original.account.id,
                                  userId: account.id,
                                  userEmail: account.email,
                                  accountName: row.original.account.name,
                                });
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </Popover.Dropdown>
                )}
              </Popover>
            </Group>
          </Group>
        );
      },
    },

    {
      accessorKey: "dailyLimit",
      header: "DAILY LIMIT",
      enableSorting: false,
      size: 150,

      accessorFn: (row) => {
        const dailyLimit =
          row?.limits?.filter((el) => el.name === "LMTTZ54")[0]?.value ||
          "No limits";
        return typeof dailyLimit === "number" ? dailyLimit.toFixed(2) : "∞";
      },
      Cell: ({ row }) => {
        const dailyLimit = row.original?.limits?.filter(
          (el) => el.name === "LMTTZ54"
        )[0]?.value;

        return (
          <div>
            {typeof dailyLimit === "number" ? (
              <span>€ {dailyLimit.toFixed(2)}</span>
            ) : (
              "∞"
            )}
          </div>
        );
      },
    },

    {
      accessorKey: "weeklyLimit",
      header: "WEEKLY LIMIT",
      size: 150,

      enableSorting: false,
      accessorFn: (row) => {
        const weeklyLimit =
          row?.limits?.filter((el) => el.name === "LMTTZ56")[0]?.value ||
          "No limits";

        return typeof weeklyLimit === "number" ? weeklyLimit.toFixed(2) : "∞";
      },
      Cell: ({ row }) => {
        const weeklyLimit = row.original?.limits.filter(
          (el) => el.name === "LMTTZ56"
        )[0]?.value;

        return (
          <div>
            {typeof weeklyLimit === "number" ? (
              <span>€ {weeklyLimit.toFixed(2)}</span>
            ) : (
              "∞"
            )}
          </div>
        );
      },
    },

    {
      accessorKey: "monthlyLimit",
      header: "MONTHLY LIMIT",
      enableSorting: false,
      size: 150,

      accessorFn: (row) => {
        const monthlyLimit =
          row.original?.limits?.filter((el) => el.name === "LMTTZ55")[0]
            ?.value || "No limits";
        return typeof monthlyLimit === "number" ? monthlyLimit.toFixed(2) : "∞";
      },
      Cell: ({ row }) => {
        const monthlyLimit = row.original?.limits?.filter(
          (el) => el.name === "LMTTZ55"
        )[0]?.value;

        return (
          <div>
            {typeof monthlyLimit === "number" ? (
              <span>€ {monthlyLimit.toFixed(2)} </span>
            ) : (
              "∞"
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export default ColumnsCards;
