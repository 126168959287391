import { height, maxHeight, minWidth, width } from "@mui/system";

export const widthStatusTitle = "130px";

export const maxWidthColumns = "250px";
export const maxWidthActions = "160px";
export const maxHeightActions = "550px";
export const maxHeightActionsMobile = "300px";

export const customTimeZoneSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    // height: "50px",
    // display: "flex",
    // alignItems: "center",
    borderRadius: "4px",
    borderColor: state.isFocused || state.isSelected ? "#21a038" : "#AAAAAA",
    boxShadow: state.isFocused && "none",
    "&:hover": {
      borderColor: "#21a038",
    },
    cursor: "pointer",
    minWidth: "300px",
    maxWidth: "300px",
    "@media (max-width: 500px)": {
      width: "100%",
      maxWidth: "none",
      minWidth: "none",
    },
  }),
  // valueContainer: (provided) => ({
  //   ...provided,
  //   padding: "0 12px",
  //   height: "100%",
  //   display: "flex",
  //   alignItems: "center",
  // }),
  // singleValue: (provided) => ({
  //   ...provided,
  //   position: "relative",
  //   transform: "none",
  //   transition: "none",
  //   margin: 0,
  //   padding: 0,
  //   lineHeight: 1,
  // }),
  placeholder: (provided) => ({
    ...provided,
    "@media (max-width: 500px)": {
      fontSize: "12px !шьзщкефте",
    },
  }),
  // dropdownIndicator: (provided) => ({
  //   ...provided,
  //   padding: "8px",
  //   color: "#333",
  //   display: "flex",
  //   alignItems: "center",
  //   "&:hover": {
  //     color: "#232323",
  //   },
  // }),
  // indicatorSeparator: () => ({
  //   display: "none",
  // }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "4px",
    marginTop: "4px",
    zIndex: 100000,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#21a038" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "#21a038",
      color: "white",
    },
    padding: "10px",
    cursor: "pointer",
  }),
};
