import { Link, useNavigate } from "react-router-dom";
import TwoFaIcon from "../../icons/TwoFaIcon";
import { Button } from "../../components/Button/Button";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { getUserData, logoutUser } from "../../api/userApi";
import { PAGE_ROUTES } from "../../pages/constants";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import TimezoneSelect from "react-timezone-select";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { customTimeZoneSelectStyles } from "../../styles/constants";
import { showToast } from "../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../constants/constants";

const ProfileSettings = () => {
  const { userTimeZone, setUserTimeZone } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [selectedTimeZone, setSelectedTimeZone] = useState(userTimeZone);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { data, isLoading, isFetching, isError, error } = useAuthQuery({
    queryKey: ["userProfileSettings"],
    queryFn: getUserData,
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
  const navigate = useNavigate();
  const profileData = data?.data;

  const handleChangeTimeZone = useCallback(() => {
    if (userTimeZone === selectedTimeZone) {
      showToast("error", "You already have this time zone selected ");
      return;
    }

    setUserTimeZone(selectedTimeZone);
    localStorage.setItem("userTimeZone", selectedTimeZone);
    showToast(
      "success",
      `Your time zone has been successfully changed to ${selectedTimeZone}`
    );
  }, [selectedTimeZone, userTimeZone]);

  if (isError) {
    return <div>{error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await logoutUser();
      localStorage.removeItem("token");
      queryClient.invalidateQueries(["userProfileSettings"]);
      navigate(PAGE_ROUTES.LOGIN);
      showToast("success", NOTIFICATION_INFO.LOGOUT);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.profileSettigns}>
      <div>
        <div className={clsx(styles.flexRow)}>
          <h2>Status</h2>
          <span className={styles.statusIsActive}>Active</span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Registration Date</h2>
          <span>
            <DateFormatter
              date={profileData?.registred_at}
              loading={isLoading}
            />
          </span>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>Name</h2>
          <div className={styles.profileChangeContainer}>
            {isLoading || isFetching ? (
              <div className={styles.textSkeleton}></div>
            ) : (
              <span>{profileData?.name}</span>
            )}
            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>
        <div className={clsx(styles.flexRow)}>
          <h2>E-mail</h2>
          {isLoading || isFetching ? (
            <div className={styles.profileChangeContainer}>
              <div className={styles.textSkeleton}></div>
            </div>
          ) : (
            <span>{profileData?.email}</span>
          )}
        </div>

        <div className={clsx(styles.flexRow)}>
          <h2>Telegram Chat</h2>
          <div className={styles.profileChangeContainer}>
            {isLoading || isFetching ? (
              <div className={styles.textSkeleton}></div>
            ) : (
              <span>{profileData?.telegram}</span>
            )}

            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>

        <div className={clsx(styles.flexRow)}>
          <h2>Password</h2>
          <div className={styles.profileChangeContainer}>
            <span>******</span>
            <Link
              className={styles.changeProfileButtons}
              to={PAGE_ROUTES.PROFILE_PASSWORD_CHANGE}
            >
              Change
            </Link>
          </div>
        </div>

        <div className={clsx(styles.flexRow)}>
          <h2>Time Zone </h2>
          <div className={styles.profileTimeZoneContainer}>
            {/* <div
              onClick={() => setMenuIsOpen((prev) => !prev)}
              onTouchEnd={() => setMenuIsOpen((prev) => !prev)}
              className={styles.customSelectWrapper}
              ref={selectWrapperRef}
            > */}
            <TimezoneSelect
              value={selectedTimeZone}
              onChange={(value) => setSelectedTimeZone(value.value)}
              menuIsOpen={menuIsOpen}
              onMenuOpen={() => setMenuIsOpen(true)}
              onMenuClose={() => setMenuIsOpen(false)}
              closeMenuOnScroll={false}
              styles={customTimeZoneSelectStyles}
              components={{
                DropdownIndicator: VisualDropdownIndicator,
              }}
            />
            {/* </div> */}

            <Button
              className={styles.changeProfileButtons}
              onClick={handleChangeTimeZone}
            >
              Save
            </Button>
          </div>
        </div>

        <div className={clsx(styles.flexRow, styles.flexRowMobile)}>
          <div>
            <h2>Two-factor authentication*</h2>
            <span className={styles.twoFaSpan}>
              We do not allow you to turn it off for security reasons.
            </span>
          </div>
          <div>
            <TwoFaIcon />
          </div>
        </div>
        <div className={clsx(styles.flexRow, styles.flexRowMobile)}>
          <h2>Logout from account</h2>

          <Button onClick={handleLogout} className={styles.profileChangeLogout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;

const VisualDropdownIndicator = (props) => {
  const { selectProps } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        cursor: "default",
      }}
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2L5 7L10 2H0Z"
          fill={selectProps.menuIsOpen ? "#232323" : "#AAAAAA"}
        />
      </svg>
    </div>
  );
};
