import React from "react";
import ForAdvertising from "../../images/ForAdvertising.png";
import styles from "./styles.module.scss";
import { getBackgroundForSystemMobile } from "../../utils/getBackgroundForSystem";
import useIsMobile from "../../hooks/useIsMobile";

const CardItemMobile = ({ card }) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={styles.cardItemBackgroundMobile}
      style={{
        backgroundImage: getBackgroundForSystemMobile(card?.system, isMobile),
        height: "100px",
      }}
    >
      {card ? (
        <>
          <p className={styles.cardItemP}>
            {card?.bin.slice(0, 4)} {card?.bin.slice(4, 8)}{" "}
            <span
              className={styles.cardItemSpan}
              style={{ letterSpacing: "0.54rem" }}
            >
              .... ....
            </span>
          </p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CardItemMobile;
