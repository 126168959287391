import { Link, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import styles from "./MainLayout.module.scss";
import FooterMainLayout from "../../components/footer/FooterMainLayout/FooterMainLayout";
import { ActionNotification } from "../Notification/ActionNotification";
import { Notifications } from "../Notifications/Notifications";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const MainLayout = () => {
  return (
    <div className={styles.mainLayout}>
      <div className={styles.wrapperMainLayout}>
        <Sidebar />

        <div className={styles.childrenLayout}>
          <MobileHeader />
          <div className={styles.containerLayout}>
            {/* <ActionNotification position="top-right" autoClose={2000} /> */}
            <Outlet />
            <Notifications />
            <FooterMainLayout />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
