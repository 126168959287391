import React from "react";

const RadioOnIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="7"
        fill="white"
        stroke="url(#paint0_linear_198_8958)"
        strokeWidth="6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_198_8958"
          x1="0"
          y1="0"
          x2="19.4921"
          y2="-0.48335"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83DA4E" />
          <stop offset="0.511453" stopColor="#21A038" />
          <stop offset="0.98" stopColor="#54ACB8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RadioOnIcon;
