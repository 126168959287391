import { useContext, useEffect, useState } from "react";
import { Button, Group, Box, Popover, Pagination } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import Table from "../../../components/Table/Table";
import WalletTopUp from "../../../icons/WalletTopUp";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAccounts, getExportAccounts } from "../../../api/getAccounts";
import styles from "./styles.module.scss";
import ModalIssueAccount from "../../Modals/ModalIssueAccount/ModalIssueAccount";
import TopUpModal from "../../Modals/TopUpModal/TopUpModal";
import EditableName from "../DashBoard/EditNamePopup";
import { AuthContext } from "../../../context/AuthContext";
import { getAllTeamMembers } from "../../../api/usersApi";
import AddUserModal from "../../Modals/AddUserModal/AddUserModal";
import RemoveUserModal from "../../Modals/RemoveUserModal/RemoveUserModal";
import ArrowBottom from "../../../icons/ArrowBottom";
import TransferModal from "../../Modals/TransferModal/TransferModal";
import Skeleton from "../../../components/Skeleton/Skeleton";
import ReloadIcon from "../../../icons/ReloadIcon";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { useLocation } from "react-router-dom";
import ExcelButton from "../../../components/ExcelButton/ExcelButton";
import { getAllAccountsData } from "../../../api/getExcel";
import { useClientInvalidateData } from "../../../hooks/useClientInvalidateData";
import { getUserRolePriority } from "../../../utils/getUserPriority";
import { handleExcelDownload } from "../../../utils/excelDownload";
import {
  maxHeightActions,
  maxHeightActionsMobile,
  maxWidthColumns,
} from "../../../styles/constants";
import { optionsTable } from "../../../constants/constants";
import useIsMobile from "../../../hooks/useIsMobile";
import PaymentBeingProcessedModal from "../../Modals/PaymentBeingProcessed/PaymentBeingProcessedModal";

const AllAccountsTable = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0 });
  const pageSize = 15;
  const location = useLocation();
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["accounts", pagination.pageIndex, pageSize],
    queryFn: () =>
      getAccounts({
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
  });

  const [usersToGrant, setUsersToGrant] = useState();

  const [isProcessedModalShow, setProcessedModalShow] = useState(false);
  const [processedModalType, setIsProcessedModalType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [issueModal, setIssueModal] = useState(false);
  const [topUpModal, setTopUpModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [addUserData, setAddUserData] = useState();
  const [removeUserData, setRemoveUserData] = useState();
  const [removeMemberModal, setRemoveMemberModal] = useState(false);

  const { userData } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    iban: "",
  });
  const isMobile = useIsMobile();
  useEffect(() => {
    const queryParams = location.search.substring(1);
    if (queryParams) {
      setSelectedUser(queryParams);
      setTopUpModal(true);
    }
  }, [location.search]);

  const {
    data: teamMembersData,
    isLoading: isTeamMembersLoading,
    isError: isTeamMembersError,
  } = useQuery({
    queryKey: ["teamMembers"],
    queryFn: getAllTeamMembers,
  });
  const { handleInvalidateData } = useClientInvalidateData({
    queryKey: "teamMembers",
  });

  useEffect(() => {
    if (data) {
      setTableData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (teamMembersData) {
      setUsersToGrant(teamMembersData.data.data);
    }
  }, [teamMembersData]);

  const columns = [
    {
      accessorKey: "accountName",
      header: "ACCOUNT’S NAME / IBAN",
      enableSorting: false,
      Cell: ({ row }) => {
        return (
          <div
            className={styles.tableAccountName}
            style={{
              maxWidth: maxWidthColumns,
              overflow: "auto",
            }}
          >
            <div className={styles.tableAccountsNameTitle}>
              <p
                className="semiHedline"
                style={{
                  color: "#232323",
                  overflow: "auto",
                  textOverflow: "ellipsis",
                }}
              >
                {row.original.name}
              </p>

              {userData?.role !== "Member" && (
                <EditableName
                  key={row.original.name}
                  account={row.original}
                  setTableData={setTableData}
                />
              )}
            </div>
            <div className={`primaryText ${styles.tableAccountsNameIban}`}>
              {row.original.iban}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "users",
      header: "USERS",
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const [usersPopoverOpen, setUsersPopoverOpen] = useState(false);
        const [addUserPopoverOpen, setAddUserPopoverOpen] = useState(false);

        const handleAddUser = (email, name, id, iban) => {
          setAddMemberModal(true);
          setAddUserPopoverOpen(false);
          setAddUserData({
            email,
            name,
            id,
            iban,
            accountName: row.original.name,
          });
        };

        const handleRemoveUser = (email, name, userId, id) => {
          setRemoveMemberModal(true);
          setUsersPopoverOpen(false);
          setRemoveUserData({
            email,
            name,
            userId,
            id,
            accountName: row.original.name,
          });
        };

        const accountsIban = usersToGrant?.filter((user) =>
          user.accountsToGrants.some(
            (account) => account.iban === row.original.iban
          )
        );

        const usersWithMatchingIban = usersToGrant
          ?.filter((user) =>
            user.accounts.some((account) => account.iban === row.original.iban)
          )
          .map((user) => ({
            email: user.email,
            name: user.name,
            id: user.id,
            role: user.role,
          }));

        return (
          <Group
            spacing={0}
            style={{
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Group spacing="xs">
              <p
                style={{
                  marginRight: "4px",
                  color: "#21A038",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (usersWithMatchingIban?.length) {
                    setUsersPopoverOpen((prev) => !prev);
                  }
                }}
              >
                {row.original.usersCount}
              </p>
              <Popover
                opened={usersPopoverOpen}
                onClose={() => setUsersPopoverOpen(false)}
                position="bottom-start"
                withArrow
                withinPortal
                styles={{
                  dropdown: {
                    position: "absolute",
                    zIndex: 9,
                  },
                }}
                disabled={userData?.role === "Member"}
              >
                <Popover.Target>
                  <Button
                    size="xs"
                    sx={{
                      button: {
                        backgroundColor: "transparent",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      opacity: userData.role === "Member" ? "0.5" : "1",
                    }}
                    variant="subtle"
                    onClick={() => {
                      if (usersWithMatchingIban?.length) {
                        setUsersPopoverOpen((prev) => !prev);
                      }
                    }}
                    style={{ padding: "0" }}
                  >
                    <ArrowBottom />
                  </Button>
                </Popover.Target>

                <Popover.Dropdown>
                  <div className={styles.popoverUsersContainer}>
                    {usersWithMatchingIban?.map((user, idx) => (
                      <div
                        key={idx}
                        className={styles.popoverUsersItem}
                        onClick={() => {
                          if (getUserRolePriority(user, userData))
                            handleRemoveUser(
                              user.email,
                              user.name,
                              row.original.ID,
                              user.id
                            );
                        }}
                      >
                        <p key={idx} className="primaryText">
                          {user.email}
                        </p>
                        {getUserRolePriority(user, userData) && (
                          <span className="primaryText">Remove</span>
                        )}
                      </div>
                    ))}
                  </div>
                </Popover.Dropdown>
              </Popover>
            </Group>

            <Popover
              opened={addUserPopoverOpen}
              onClose={() => setAddUserPopoverOpen(false)}
              position="bottom-start"
              withArrow
              withinPortal
              styles={{
                dropdown: {
                  maxWidth: "300px",
                  padding: "16px",
                  zIndex: 9,
                },
              }}
              disabled={userData?.role === "Member"}
            >
              <Popover.Target>
                <Button
                  size="xs"
                  style={{
                    background:
                      "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                    opacity: userData.role === "Member" ? "0.5" : "1",
                    color: "#fff",
                    height: "24px",
                    border: "none",
                    borderRadius: "8px",
                  }}
                  onClick={() => setAddUserPopoverOpen((prev) => !prev)}
                >
                  Add User
                </Button>
              </Popover.Target>

              <Popover.Dropdown
                style={{
                  maxHeight: isMobile
                    ? maxHeightActionsMobile
                    : maxHeightActions,
                  overflow: "auto",
                }}
              >
                <div className={styles.popoverUsersContainer}>
                  {accountsIban &&
                    accountsIban.map((account, idx) => {
                      return (
                        <div
                          key={idx}
                          className={styles.popoverUsersItem}
                          onClick={() => {
                            if (getUserRolePriority(account, userData)) {
                              handleAddUser(
                                account.email,
                                account.name,
                                account.id,
                                row.original.ID
                              );
                            }
                          }}
                        >
                          <p className="primaryText">{account.email}</p>
                          {getUserRolePriority(account, userData) && (
                            <span className="primaryText">Add</span>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Popover.Dropdown>
            </Popover>
          </Group>
        );
      },
    },

    {
      accessorKey: "notification",
      header: "NOTIFICATION",
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <p>{row.original.balance ? "-" : "Top-Up your balance"}</p>
      ),
    },
    {
      accessorKey: "availableBalance",
      header: "AVAILABLE BALANCE",
      enableSorting: false,
      Cell: ({ row }) => {
        const balance = row.original?.balance;

        return <>{balance != null ? <p>€{balance}</p> : <p>€0</p>}</>;
      },
      filterVariant: "range-slider",
      filterFn: "betweenInclusive",
      mantineFilterRangeSliderProps: {
        max: 50_000,
        min: 0,
        step: 250,
        label: (value) =>
          value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
      },
    },
    {
      accessorKey: "actions",
      header: "ACTIONS",
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        if (userData?.role === "Member") {
          return "Unable for this role";
        }
        const handleTopUpClick = () => {
          setSelectedUser({
            name: row.original.name,
            iban: row.original.iban,
            id: row.original.ID,
          });
          setTopUpModal(true);
        };

        const handleTransferClick = () => {
          setSelectedUser({
            name: row.original.name,
            iban: row.original.iban,
            id: row.original.ID,
            value: row.original.availableAmount,
          });
          setTransferModal(true);
        };

        return (
          <Group style={{ whiteSpace: "nowrap", minWidth: "170px" }}>
            <Button
              style={{
                height: "24px",
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
              }}
              size="xs"
              onClick={handleTopUpClick}
            >
              <div className={styles.topUpBtn}>
                <p>Top up</p> <WalletTopUp size="16px" />
              </div>
            </Button>

            <Button
              style={{
                background: "transparent",
                color: "#232323",
                border: "1px solid #232323",
                height: "24px",
                borderRadius: "8px",
              }}
              size="xs"
              onClick={handleTransferClick}
            >
              Transfer
            </Button>
          </Group>
        );
      },
    },
  ];

  return (
    <Box
      style={{
        borderRadius: "16px",
        overflow: topUpModal ? "hidden" : "auto",
        background: "#fff",
        padding: "16px 24px",
      }}
    >
      <Group
        position="apart"
        style={{
          justifyContent: "start",
          marginBottom: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div className={styles.allAccountsHeader}>
          <div className={styles.allAccountsHeaderLeft}>
            <h3>ALL ACCOUNTS</h3>
            <ReloadIcon
              onClick={handleInvalidateData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <ExcelButton
            onSubmit={() =>
              handleExcelDownload({
                fn: () => getExportAccounts(),
                fileName: "accounts",
              })
            }
          />
        </div>
        {userData?.role === "Member" ? (
          ""
        ) : (
          <div className={styles.allAccountsTableHeader}>
            <Button
              rightIcon={<WalletTopUp size="24px" />}
              style={{
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                color: "#fff",
                cursor:
                  isFetching || userData?.role === "Member"
                    ? "not-allowed"
                    : "pointer",
                border: "none",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (!isFetching && userData?.role !== "Member") {
                  setTopUpModal(true);
                  setSelectedUser("");
                }
              }}
            >
              Top up balance
            </Button>
            <Button
              rightIcon={<IconPlus />}
              style={{
                background: "transparent",
                color: "#232323",
                border: "1.5px solid #C8C8C8",
                cursor: userData?.role === "Member" ? "not-allowed" : "pointer",
                borderRadius: "8px",
              }}
              onClick={() => {
                if (userData?.role !== "Member") {
                  setIssueModal(true);
                }
              }}
            >
              Issue account
            </Button>
          </div>
        )}
      </Group>
      {isLoading ? (
        <Skeleton className={styles.tableSkeleton} isSpinner={true} />
      ) : (
        data && (
          <Table
            columns={columns}
            data={tableData}
            enablePagination={false}
            initialState={{ pageSize: 5 }}
            enableColumnActions={false}
            enableColumnFilters={true}
            enableGlobalFilter={false}
            enableHiding={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            mantineTableProps={{
              ...optionsTable,
            }}
          />
        )
      )}
      <div style={{ marginTop: "16px" }}>
        <Pagination
          value={pagination.pageIndex + 1}
          onChange={(page) => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: page - 1,
            }));
          }}
          total={
            data?.meta?.last_page ??
            Math.ceil((data?.meta?.total ?? 0) / pageSize)
          }
          position="right"
          withEdges
        />
      </div>
      {issueModal && (
        <ModalIssueAccount
          setIssueModal={setIssueModal}
          reload={handleInvalidateData}
        />
      )}
      {topUpModal && tableData && !isLoading && (
        <TopUpModal
          setTopUpModal={setTopUpModal}
          user={selectedUser}
          setProcessedModalShow={setProcessedModalShow}
          setIsProcessedModalType={setIsProcessedModalType}
        />
      )}
      {transferModal && tableData && (
        <TransferModal
          setTransferModal={setTransferModal}
          selectedUser={selectedUser}
          reload={handleInvalidateData}
          setProcessedModalShow={setProcessedModalShow}
          setIsProcessedModalType={setIsProcessedModalType}
        />
      )}
      {addMemberModal && (
        <AddUserModal
          setAddMemberModal={setAddMemberModal}
          addUserData={addUserData}
          reload={handleInvalidateData}
        />
      )}
      {removeMemberModal && (
        <RemoveUserModal
          setRemoveMemberModal={setRemoveMemberModal}
          removeUserData={removeUserData}
          reload={handleInvalidateData}
        />
      )}
      {isProcessedModalShow && (
        <PaymentBeingProcessedModal
          setProcessedModalShow={setProcessedModalShow}
          processedModalType={processedModalType}
        />
      )}
    </Box>
  );
};

export default AllAccountsTable;
