import React from "react";

const DashboardMobile = ({ fill }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4248 2.25C4.14663 2.25 2.2998 4.09682 2.2998 6.375V14.625C2.2998 16.9032 4.14663 18.75 6.4248 18.75H19.1748C21.453 18.75 23.2998 16.9032 23.2998 14.625V6.375C23.2998 4.09682 21.453 2.25 19.1748 2.25H6.4248ZM3.7998 6.375C3.7998 4.92525 4.97505 3.75 6.4248 3.75H19.1748C20.6246 3.75 21.7998 4.92525 21.7998 6.375V14.625C21.7998 16.0747 20.6246 17.25 19.1748 17.25H6.4248C4.97505 17.25 3.7998 16.0747 3.7998 14.625V6.375Z"
        fill={fill}
      />
      <path
        d="M6.7998 20.25C6.38559 20.25 6.0498 20.5858 6.0498 21C6.0498 21.4142 6.38559 21.75 6.7998 21.75H18.7998C19.214 21.75 19.5498 21.4142 19.5498 21C19.5498 20.5858 19.214 20.25 18.7998 20.25H6.7998Z"
        fill={fill}
      />
    </svg>
  );
};

export default DashboardMobile;
