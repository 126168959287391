import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getAccounts = async ({ page, perPage }) => {
  const result = await axiosAuth.get(API_PATHS.ACCOUNTS, {
    params: {
      page: page,
      perPage: perPage,
    },
  });
  return result.data;
};

//все аккаунты
export const getAllAccountsAddMember = async (perPage) => {
  const params = perPage ? { perPage } : {};
  const result = await axiosAuth.get(API_PATHS.ACCOUNTS, { params });
  return result.data;
};

export const fundAccount = async (data) => {
  const result = axiosAuth.post(API_PATHS.FUND_ACCOUNT, data);

  return result;
};

export const getExchangeRate = async (perPage) => {
  const params = perPage ? { perPage } : {};

  const result = axiosAuth.get(API_PATHS.EXCHANGE_RATE, { params });

  return result;
};

export const accountOperations = async ({ page, perPage, params }) => {
  const response = await axiosAuth.get(API_PATHS.ACCOUNTS_OPERATIONS, {
    params: {
      ...params,
      page: page,
      perPage: perPage,
    },
  });
  return response.data;
};

export const confirmFundAccount = async (id, data) => {
  const result = axiosAuth.post(`api/accounts/fund/${id}/confirm`, data);

  return result;
};

export const addUserToAccount = (id) => {
  return axiosAuth.post(`/api/accounts/${id}/add-users`);
};

export const changeAccount = (id, data) => {
  return axiosAuth.post(`/api/accounts/${id}`, data);
};

export const grantAccessToAcc = async (id, body) => {
  return axiosAuth.post(`/api/accounts/${id}/add-users`, body);
};

export const removeAccessToAcc = async (id, userId) => {
  return axiosAuth.delete(`/api/accounts/${userId}/user/${id}`);
};

export const transferAmountToAccount = async (body) => {
  return axiosAuth.post(API_PATHS.TRANSFER_AMOUNT, body);
};

export const getExportAccounts = async () => {
  return await axiosAuth.get(API_PATHS.EXCEL_PAYMENTS, {
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  });
};

export const getExportBalanceHistory = async ({ params }) => {
  return await axiosAuth.get(API_PATHS.BALANCE_HISTORY_EXPORT, {
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
    params: {
      ...params,
    },
  });
};

export const getAllAvailableAccounts = async ({ perPage = 100 }) => {
  return axiosAuth.get(API_PATHS.ACCOUNTS, {
    params: {
      perPage: perPage,
    },
  });
};
