import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CardItem from "../../../components/CardIssuanceItem/CardItem";
import ArrowLink from "../../../icons/ArrowLink";
import { getSystemTitle } from "../../../utils/getBackgroundForSystem";
import ExitIcon from "../../../icons/ExitIcon";
import { useForm, Controller } from "react-hook-form";
import {
  Select,
  MenuItem,
  Box,
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Switch,
  styled,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  ALL_MERCHANTS,
  cardsData,
  cardsIssuanceData,
  limitOptions,
  NOTIFICATION_INFO,
} from "../../../constants/constants";
import { getAccounts } from "../../../api/getAccounts";
import { cardIssueLink } from "../../../pages/constants";
import { issueCard } from "../../../api/cardsApi";
import { showToast } from "../../Notification/ActionNotification";
import { Button } from "../../../components/Button/Button";
import CardItemMobile from "../../../components/CardIssuanceItem/CardItemMobile";
import ArrowBottomMobie from "../../../icons/ArrowBottomMobie";
import { useQueryClient } from "@tanstack/react-query";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import QuestionIcons from "../../../icons/QuestionIcons";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  maxHeightActions,
  maxHeightActionsMobile,
} from "../../../styles/constants";
import { getPaymentSystemLabel } from "../../../utils/getPaymentSystem";

const IssueCardModal = ({ data, setIssueCardModal }) => {
  const [accountsData, setAccountsData] = useState();
  const [cardData, setCardData] = useState(data || null);
  const system = getSystemTitle(cardData?.system);
  const isMobile = useIsMobile();
  const [showInfo, setShowInfo] = useState(false);
  const queryClient = useQueryClient();

  const { control, handleSubmit, setValue, watch, setError } = useForm({
    defaultValues: {
      cardName: "",
      bin: cardData?.bin || "",
      accountID: "",
      limit: "",
      notificationEnable: true,
      multiple: false,
      cardsCount: 1,
    },
  });

  const binValue = watch("bin");
  const accValue = watch("accountID");
  const multiple = watch("multiple");

  const StyledTextField = styled(TextField)({
    "& .MuiInputBase-input::placeholder": {
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      textAlign: "left",
    },
    "& .MuiInputLabel-root": {
      fontFamily: "Noto Sans",
      fontWeight: 500,
      lineHeight: "20px",
      textAlign: "left",
      color: "#AAAAAA",
    },
  });

  const cardsCount = watch("cardsCount");

  const cost =
    cardsCount === 1
      ? cardsCount * 3.5
      : cardsCount >= 2 && cardsCount <= 4
      ? cardsCount * 2.5
      : cardsCount >= 5
      ? cardsCount * 1.5
      : 0;

  useEffect(() => {
    if (multiple) {
      if (watch("cardsCount") < 2) {
        setValue("cardsCount", 2);
      }
    } else {
      if (watch("cardsCount") > 1) {
        setValue("cardsCount", 1);
      }
    }
  }, [multiple, setValue, watch]);

  const handleBinChange = (event) => {
    const selectedBin = event.target.value;

    const selectedCard = cardsData.find((card) => card.bin === selectedBin);

    setCardData(selectedCard);
  };

  useEffect(() => {
    setShowInfo(isMobile ? false : true);
  }, [isMobile]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const result = await getAccounts({ page: 1, perPage: 150 });
        setAccountsData(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchAccounts();
  }, []);

  const onSubmit = async (data) => {
    try {
      const payload = {
        accountId: data.accountID,
        cardName: data.cardName,
        bin: data.bin,
        limit: data.limit,
        notificationEnable: true,
        multiple: data.multiple,
        cardsCount: data.cardsCount,
      };

      await issueCard(payload);
      showToast("success", NOTIFICATION_INFO.CARDS_ISSUED);
      queryClient.invalidateQueries("cardsTableData");
      setIssueCardModal(false);
    } catch (error) {
      console.error(error);
      const errText = error.response?.data?.data.message;
      setIssueCardModal(false);

      showToast("error", errText || NOTIFICATION_INFO.CARDS_ISSUED_ERROR);
    }
  };

  return (
    <div className={styles.issueCardModal}>
      <div className={styles.issueCardModalContainer}>
        <div className={styles.issueCardModalHeader}>
          <h1>
            {cardData ? (
              <>
                {system} BIN {cardData.bin}
              </>
            ) : (
              "SELECT BIN FOR CARD"
            )}
          </h1>
          <ExitIcon
            styles={styles.exitButton}
            onClick={() => setIssueCardModal(false)}
          />
        </div>
        <div className={styles.issueCardModalContainerMain}>
          <div className={styles.issueCardModalContainerLeft}>
            <div className={styles.cardDesktop}>
              <CardItem card={cardData} />
            </div>
            <div className={styles.cardMobile}>
              <CardItemMobile card={cardData} />{" "}
              <div onClick={() => setShowInfo(!showInfo)}>
                <span>Details</span>

                <ArrowBottomMobie showInfo={showInfo} />
              </div>
            </div>
            <div
              className={styles.issueCardModalInformationContainer}
              style={{ display: showInfo ? "flex" : "none" }}
            >
              {cardsIssuanceData.map((item, idx) => (
                <div key={idx} className={styles.issueCardModalInformation}>
                  <p className="semiHedline" style={{ color: "#232323" }}>
                    {item.title}
                  </p>
                  <span className="primaryText">{item.text}</span>
                </div>
              ))}
              <a
                href={ALL_MERCHANTS}
                target="_blank"
                rel="noreferrer"
                className={`${styles.issueCardModalInformation} semiHedline`}
              >
                <span className={styles.link}>Available merchants</span>
                <ArrowLink />
              </a>
            </div>
          </div>
          <form
            className={styles.issueCardModalContainerRight}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              component="div"
              sx={{
                maxWidth: 400,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Controller
                name="cardName"
                control={control}
                rules={{
                  required: "Card's name is required",
                  maxLength: {
                    value: 30,
                    message: "Max length is 30 characters",
                  },
                }}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    {...field}
                    label="Card's Name*"
                    placeholder="Enter a name"
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    onBlur={() => {
                      if (!field.value) {
                        setError("cardName", {
                          type: "manual",
                          message: "Card's name is required",
                        });
                      } else if (field.value.length > 30) {
                        setError("cardName", {
                          type: "manual",
                          message: "Max length is 30 characters",
                        });
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        backgroundColor: "white",
                        padding: "0 3px",
                        color: fieldState.error
                          ? "rgb(211, 47, 47)"
                          : "#AAAAAA",
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="bin"
                control={control}
                rules={{ required: "BIN is required" }}
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={!!fieldState.error}
                    sx={{
                      // maxHeight: "40px",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                        color: binValue ? "#black" : "#aaaaaa",
                        fontWeight: "500",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <InputLabel
                      shrink={true}
                      sx={{
                        background: "white",
                        padding: "5px",
                        top: "-3px",
                        fontFamily: "Noto Sans",
                        fontWeight: 500,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#AAAAAA",
                      }}
                    >
                      BIN*
                    </InputLabel>
                    <Select
                      {...field}
                      displayEmpty
                      onChange={(event) => {
                        handleBinChange(event);
                        field.onChange(event);
                      }}
                      onBlur={() => {
                        if (!field.value) {
                          setError("bin", {
                            type: "manual",
                            message: "BIN is required",
                          });
                        }
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          top: "calc(50% - 12px)",
                        },
                      }}
                    >
                      <MenuItem
                        value=""
                        disabled
                        sx={{ fontSize: "14px", color: "#AAAAAA" }}
                      >
                        Select BIN
                      </MenuItem>
                      {cardsData.map((card, idx) => (
                        <MenuItem key={idx} value={card.bin}>
                          {card.bin} {`(${getPaymentSystemLabel(card.system)})`}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              <Controller
                name="accountID"
                control={control}
                rules={{ required: "Account is required" }}
                InputLabelProps={{
                  shrink: true,
                }}
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    error={!!fieldState.error}
                    sx={{
                      // maxHeight: "40px",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                        color: accValue ? "#000000" : "#AAAAAA",
                        fontWeight: "500",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <InputLabel
                      shrink
                      sx={{
                        backgroundColor: "white",
                        padding: "0 3px",
                        fontFamily: "Noto Sans",
                        fontWeight: 500,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#AAAAAA",
                      }}
                    >
                      Account*
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="Account-select-label"
                      id="Account-select"
                      label="Account*"
                      displayEmpty
                      defaultValue=""
                      sx={{
                        height: "40px",
                        padding: 0,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#AAAAAA",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: isMobile
                              ? maxHeightActionsMobile
                              : maxHeightActions,
                            overflow: "auto",
                          },
                        },
                      }}
                      onBlur={() => {
                        if (!field.value) {
                          setError("accountID", {
                            type: "manual",
                            message: "Account is required",
                          });
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select the account
                      </MenuItem>
                      {accountsData?.map((account, idx) => (
                        <MenuItem key={idx} value={account?.ID}>
                          {account?.name.length > 25
                            ? `${account?.name.slice(0, 25)}...`
                            : account?.name}
                          , {account?.iban}, {account?.balance} EUR
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />

              <Controller
                name="limit"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    {...field}
                    label="Limit for 30 days"
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                      endAdornment: (
                        <CustomTooltip
                          placement={isMobile ? "top-start" : "bottom-start"}
                          title={
                            "If you set a limit, for example, 1000€, then you will not spend more than 1000€ with this card totally"
                          }
                          arrow
                        >
                          <span className={styles.containerQuestion}>
                            <QuestionIcons fill="#21A038" />
                          </span>
                        </CustomTooltip>
                      ),
                      style: {
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#464646",
                      },
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        backgroundColor: "white",
                        padding: "0 3px",
                      },
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                )}
              />
              <div className={styles.limitAmountButtons}>
                {limitOptions.map((option) => (
                  <button
                    key={option}
                    type="button"
                    variant="outlined"
                    className={styles.limitAmountButton}
                    onClick={() => setValue("limit", option)}
                    style={{ color: "#232323" }}
                  >
                    {option} €
                  </button>
                ))}
              </div>
              <Controller
                name="multiple"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Issue multiple cards"
                    labelPlacement="start"
                    sx={{
                      justifyContent: "space-between",
                      margin: 0,
                      width: "100%",
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        textAlign: "left",
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="cardsCount"
                control={control}
                rules={{
                  validate: (value, { multiple }) => {
                    if (!multiple) return true;

                    return (
                      (!isNaN(value) && value >= 2 && value <= 10) ||
                      "Value must be between 2 and 10"
                    );
                  },
                }}
                render={({ field, fieldState }) => (
                  <Controller
                    name="multiple"
                    control={control}
                    render={({ field: issueField }) => (
                      <StyledTextField
                        {...field}
                        label="How many cards do you need?"
                        placeholder="Enter the amount of cards"
                        variant="outlined"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 2,
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <div className={styles.containerHelperPriceCards}>
                              <p>Prices</p>
                              <CustomTooltip
                                placement={
                                  isMobile ? "top-start" : "bottom-start"
                                }
                                title={
                                  <div className={styles.containerHelperTekst}>
                                    <p>Card prices</p>
                                    <div className={styles.wrapperHelperTekst}>
                                      <div className={styles.itemTekstHelper}>
                                        <span className={styles.priceBold}>
                                          €3.5
                                        </span>
                                        <span>for 1 card</span>
                                      </div>
                                      <div className={styles.itemTekstHelper}>
                                        <span className={styles.priceBold}>
                                          €2.5
                                        </span>
                                        <span>from 2 to 4 cards</span>
                                      </div>
                                      <div className={styles.itemTekstHelper}>
                                        <span className={styles.priceBold}>
                                          €1.5
                                        </span>
                                        <span>from 5 cards</span>
                                      </div>
                                    </div>
                                  </div>
                                }
                                arrow
                              >
                                <span className={styles.containerQuestion}>
                                  <QuestionIcons fill="#21A038" />
                                </span>
                              </CustomTooltip>
                            </div>
                          ),
                        }}
                        sx={{
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                            padding: "0 3px",
                            color: "#AAAAAA",
                          },
                        }}
                        disabled={!issueField.value}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value ? Number(e.target.value) : ""
                          )
                        }
                      />
                    )}
                  />
                )}
              />

              <div className={styles.issueCardFooter}>
                <div className={styles.issueCardFooterLeft}>
                  <h1>€{cost}</h1>
                  <span className="secondaryText">
                    Total
                    <br />
                    cost
                  </span>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Issue now
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default IssueCardModal;
