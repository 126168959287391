import React, { useCallback, useContext, useState } from "react";
import styles from "./styles.module.scss";
import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import DashboardMobile from "../../icons/DashboardMobile";
import CardsMobile from "../../icons/CardsMobile";
import PaymentsMobileIcon from "../../icons/PaymentsMobileIcon";
import CodesMobileIcon from "../../icons/CodesMobileIcon";
import { Popover } from "@mantine/core";
import { PAGE_ROUTES } from "../../pages/constants";
import TeamManagmentIcon from "../../icons/menuItems/TeamManagmentIcon";
import NewsIcon from "../../icons/NewsIcon";
import YourManager from "../../icons/YourManager";
import TGChannelIcon from "../../icons/TGChannelIcon";
import MobileAccountIcon from "../../icons/MobileAccountIcon";
import MenuNotActiveThreeLines from "../../icons/mobile/MenuNotActiveThreeLines";
import MenuActiveCross from "../../icons/mobile/MenuActiveCross";
import FBMService from "../../icons/mobile/FBMService";
import DocumentationIcon from "../../icons/DocumentationIcon";

const MobileMenu = () => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { userData } = useContext(AuthContext);

  const handlePopoverToggle = useCallback(() => {
    setPopoverOpened((prev) => !prev);
  }, []);

  const handleCLosePopover = useCallback(() => {
    setPopoverOpened(false);
  }, []);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? styles.active : "";
  };

  return (
    <div className={styles.containerMobile}>
      <Link
        className={`${styles.itemMobile} ${isActive(PAGE_ROUTES.DASHBOARD)}`}
        to={PAGE_ROUTES.DASHBOARD}
        onClick={handleCLosePopover}
      >
        <DashboardMobile
          fill={isActive(PAGE_ROUTES.DASHBOARD) ? "#21a038" : "#2F3035"}
        />
        Dashboard
      </Link>
      <Link
        className={`${styles.itemMobile} ${isActive(PAGE_ROUTES.CARDS)}`}
        to={PAGE_ROUTES.CARDS}
        onClick={handleCLosePopover}
      >
        <CardsMobile
          fill={isActive(PAGE_ROUTES.CARDS) ? "#21a038" : "#2F3035"}
        />
        Cards
      </Link>
      <Link
        className={`${styles.itemMobile} ${isActive(PAGE_ROUTES.PAYMENTS)}`}
        to={PAGE_ROUTES.PAYMENTS}
        onClick={handleCLosePopover}
      >
        <PaymentsMobileIcon
          fill={isActive(PAGE_ROUTES.PAYMENTS) ? "#21a038" : "#2F3035"}
        />{" "}
        Payments
      </Link>
      <Link
        to={PAGE_ROUTES.METAPAY_AND_3DS}
        className={`${styles.itemMobile} ${isActive(
          PAGE_ROUTES.METAPAY_AND_3DS
        )}`}
        onClick={handleCLosePopover}
      >
        <CodesMobileIcon
          fill={isActive(PAGE_ROUTES.METAPAY_AND_3DS) ? "#21a038" : "#2F3035"}
        />{" "}
        Codes
      </Link>
      <div className={styles.itemMobileLast}>
        <Popover
          opened={popoverOpened}
          onClose={() => setPopoverOpened(false)}
          position="top"
          withArrow
          transition="pop"
        >
          <Popover.Target>
            <div
              className={styles.itemMobileLast}
              onClick={handlePopoverToggle}
            >
              {popoverOpened ? (
                <MenuActiveCross />
              ) : (
                <MenuNotActiveThreeLines />
              )}
              <p style={{ color: popoverOpened ? "#21A038" : "#2F3035" }}>
                Menu
              </p>
            </div>
          </Popover.Target>
          <Popover.Dropdown>
            <div className={styles.containerAdditionalMenuItem}>
              <div className={styles.menuMobileTop}>
                <Link
                  to={PAGE_ROUTES.ACCOUNTS}
                  className={`${isActive(PAGE_ROUTES.ACCOUNTS)}`}
                  onClick={handleCLosePopover}
                >
                  <MobileAccountIcon
                    fill={
                      isActive(PAGE_ROUTES.ACCOUNTS) ? "#21a038" : "#2F3035"
                    }
                  />
                  Accounts
                </Link>
                {userData?.role !== "Member" && (
                  <Link
                    to={PAGE_ROUTES.TEAM_MANAGEMENT}
                    className={` ${isActive(PAGE_ROUTES.TEAM_MANAGEMENT)}`}
                    onClick={handleCLosePopover}
                  >
                    <TeamManagmentIcon
                      fill={
                        isActive(PAGE_ROUTES.TEAM_MANAGEMENT)
                          ? "#21a038"
                          : "#2F3035"
                      }
                    />
                    Team management
                  </Link>
                )}

                <Link
                  to={PAGE_ROUTES.FBM_SERVICES}
                  className={` ${isActive(PAGE_ROUTES.FBM_SERVICES)}`}
                  onClick={handleCLosePopover}
                >
                  <FBMService
                    fill={
                      isActive(PAGE_ROUTES.FBM_SERVICES) ? "#21a038" : "#2F3035"
                    }
                  />
                  [FBM] Services
                </Link>
              </div>

              <div className={styles.menuMobileBottom}>
                <Link
                  to={PAGE_ROUTES.NEWS}
                  className={` ${isActive(PAGE_ROUTES.NEWS)}`}
                  onClick={handleCLosePopover}
                >
                  <NewsIcon
                    fill={isActive(PAGE_ROUTES.NEWS) ? "#21a038" : "#222222"}
                  />
                  Latest news
                </Link>

                <Link
                  target="_blank"
                  to="https://docs.fbm-pay.com/"
                  onClick={handleCLosePopover}
                >
                  <DocumentationIcon />
                  Documentation
                </Link>

                <Link
                  target="_blank"
                  to={PAGE_ROUTES.YOUR_MANAGER}
                  onClick={handleCLosePopover}
                >
                  <YourManager />
                  Manager
                </Link>

                <div className={styles.containerTgChannel}>
                  <Link target="_blank" to={PAGE_ROUTES.TG_CHANNEL}>
                    <TGChannelIcon />

                    <div className={styles.containerSubscripe}>
                      Our TG channel
                      <span className={styles.subscripteLink}> Subscribe</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
    </div>
  );
};

export default MobileMenu;
