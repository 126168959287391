import React from "react";
import styles from "./styles.module.scss";
import QestionIcon from "../../../icons/QestionIcon";
import { getColor } from "../../../utils/getColorRateItem";
import Skeleton from "../../Skeleton/Skeleton";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import QuestionIcons from "../../../icons/QuestionIcons";

const DeclineRate = ({ data, isFetching, isLoading }) => {
  const dashBoardRatesData = data?.data?.data || {};

  return (
    <div className={styles.containerRate}>
      <div className={styles.containerTitle}>
        <p className="H3">TOTAL DECLINE RATE</p>
        <CustomTooltip
          placement="bottom-start"
          title="WARNING: Your total decline rate for 30 days mustn’t exceed 10% of all transactions. If exceeded, we will be forced to stop your account"
          arrow
        >
          <span className={styles.containerQuestion}>
            <QuestionIcons fill="#21A038" />
          </span>
        </CustomTooltip>
      </div>

      {isFetching || isLoading ? (
        <Skeleton className={styles.widgetSkeleton} />
      ) : (
        <div className={styles.wrapperContainerProcents}>
          <div
            className={styles.containerProcents}
            style={{
              border: `2px solid ${getColor(
                dashBoardRatesData?.totalDeclineRateWeek?.value
              )}`,
              fontSize: "24px",
              color: getColor(dashBoardRatesData?.totalDeclineRateWeek?.value),
            }}
          >
            <span className={styles.rateNumber}>
              {dashBoardRatesData.totalDeclineRateWeek?.value || 0}%{" "}
            </span>
            <span className={styles.titleINfoDeclineRate}>in last 7 days</span>
          </div>
          <div
            className={styles.containerProcents}
            style={{
              border: `2px solid ${getColor(
                dashBoardRatesData.totalDeclineRateMonth?.value
              )}`,
              fontSize: "24px",
            }}
          >
            <span
              className={styles.rateNumber}
              style={{
                color: getColor(
                  dashBoardRatesData.totalDeclineRateMonth?.value
                ),
              }}
            >
              {dashBoardRatesData.totalDeclineRateMonth?.value}%{" "}
            </span>
            <span className={styles.titleINfoDeclineRate}>in last 30 days</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DeclineRate);
