import React, { useEffect, useState } from "react";
import styles from "./TransferModal.module.scss";
import SuccessIcon from "../../../icons/SuccessIcon";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "../../../icons/CloseIcon";
import {
  getAllAvailableAccounts,
  transferAmountToAccount,
} from "../../../api/getAccounts";
import { showToast } from "../../Notification/ActionNotification";
import { NOTIFICATION_INFO } from "../../../constants/constants";
import AddIcon from "../../../icons/AddIcon";
import { useQuery } from "@tanstack/react-query";
import {
  maxHeightActions,
  maxHeightActionsMobile,
} from "../../../styles/constants";
import useIsMobile from "../../../hooks/useIsMobile";

const perPageFiltersAccs = 100;

const TransferModal = ({
  setTransferModal,
  selectedUser,
  reload,
  setProcessedModalShow,
  setIsProcessedModalType,
}) => {
  const [step, setStep] = useState(1);
  const [accountData, setAccountData] = useState(selectedUser?.iban || "");
  const [fields, setFields] = useState([
    { iban: "", name: "", value: 0, id: "", balance: 0 },
  ]);
  const [filteredTableData, setFilteredTableData] = useState();
  const [selectedIbans, setSelectedIbans] = useState([]);

  const {
    data: accountsAll,
    isLoading: isLoadingAllAccounts,
    isFetching: isFetchingAllAccounts,
  } = useQuery({
    queryKey: ["accountsAllBH", perPageFiltersAccs],
    queryFn: () => getAllAvailableAccounts(perPageFiltersAccs),
  });

  const accountsData = accountsAll?.data?.data;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (selectedUser) {
      const matchedAccount =
        accountsData?.find((account) => account.iban === selectedUser.iban) ||
        [];
      setAccountData(matchedAccount);
    }
  }, [selectedUser, accountsData]);
  useEffect(() => {
    const filteredData =
      accountsData?.filter((item) => item.iban !== accountData.iban) || [];
    setFilteredTableData(filteredData);
  }, [accountsData, accountData]);

  const handleAccountChange = (index, event) => {
    const selectedIban = event.target.value;

    setSelectedIbans((prevIbans) => {
      if (prevIbans.includes(selectedIban)) {
        return prevIbans;
      }

      const updatedIbans = [...prevIbans];
      const previousIban = fields[index]?.iban;

      if (previousIban) {
        const ibanIndex = updatedIbans.indexOf(previousIban);
        if (ibanIndex > -1) {
          updatedIbans.splice(ibanIndex, 1);
        }
      }

      updatedIbans.push(selectedIban);

      return updatedIbans;
    });

    const selectedAccount =
      accountsData?.find((account) => account.iban === selectedIban) || [];

    if (index !== undefined) {
      const updatedFields = [...fields];
      updatedFields[index] = {
        ...updatedFields[index],
        iban: selectedAccount.iban,
        name: selectedAccount.name,
        id: selectedAccount.ID,
        balance: selectedAccount.balance,
      };
      setFields(updatedFields);
    } else {
      setAccountData(selectedAccount);
    }
  };

  const removeField = (index) => {
    setFields((prevFields) => {
      const ibanToRemove = prevFields[index]?.iban;

      setSelectedIbans((prevIbans) =>
        prevIbans.filter((iban) => iban !== ibanToRemove)
      );

      return prevFields.filter((_, i) => i !== index);
    });
  };

  const handleAmountChange = (index, event) => {
    const value = parseFloat(event.target.value) || null;
    const updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], value };
    setFields(updatedFields);
  };

  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      const transformedFields = fields.map((field) => ({
        id: field.id,
        amount: field.value,
      }));
      const body = { fromId: accountData.ID, accounts: transformedFields };
      const fetchFundDetails = async (body) => {
        try {
          const result = await transferAmountToAccount(body);
          if (result.status === 200 || result.status === 204) {
            setTransferModal(false);
            showToast("success", NOTIFICATION_INFO.ACCOUNT_TRANSFER);
            setProcessedModalShow(true);
            setIsProcessedModalType("transfer");

            reload();
          }
        } catch (err) {
          if (err.status === 422) {
            showToast(
              "error",
              "The minimum balance required on the account is $10."
            );
          }
        }
      };
      fetchFundDetails(body);
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      setStep(1);
    } else if (step === 3) {
      setStep(2);
    }
  };

  const addField = () => {
    if (fields.length < 10) {
      setFields([...fields, { iban: "", name: "", value: 0, id: "" }]);
    } else {
      console.error("no more accounts");
    }
  };

  const accountBalance = accountData?.availableAmount;
  const transferAmount = fields.reduce((sum, field) => sum + field.value, 0);
  const balanceAfterTransfer =
    accountData?.availableAmount -
    fields.reduce((sum, field) => sum + field.value, 0);

  if (isLoadingAllAccounts || isFetchingAllAccounts) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h1 className={styles.modalTitle}>Transfer</h1>
        <div className={styles.modalItemsGap}>
          <div className={styles.modalContainer}>
            <div className={styles.sideSteps}>
              <div className={styles.stepRound}>
                {step === 1 ? "1" : <SuccessIcon />}
              </div>
              <div className={styles.stepLine}></div>
            </div>
            <div className={styles.singleStep}>
              <div className={styles.stepTitle}>
                <h2>Step 1</h2>
                <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                  From account
                </p>
                <div
                  className={styles.stepTitleTextField}
                  style={{ display: step === 1 ? "none" : "block" }}
                >
                  {step === 1 ? (
                    ""
                  ) : (
                    <TextField
                      id="outlined-read-only-input"
                      label="Transfer from this account"
                      value={
                        accountData
                          ? `${accountData.name}, ${accountData.iban}, ${accountData.availableAmount} EUR`
                          : "No info"
                      }
                      InputProps={{
                        readOnly: true,
                        style: {
                          fontSize: "15px",
                          fontWeight: 500,
                          color: "#464646",
                        },
                      }}
                      sx={{
                        maxHeight: "40px",
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "40px",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                          padding: "0 3px",
                          color: "#AAAAAA",
                          fontWeight: "700",
                        },
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={step === 1 ? styles.activeStep : styles.step}>
                <p className="primaryText">
                  Select the account you want to transfer money from.
                </p>
                <div className={styles.transferFormInput}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      maxHeight: "40px",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  >
                    <InputLabel
                      id="network-select-label"
                      shrink
                      sx={{
                        backgroundColor: "white",
                        padding: "0px 3px",
                        color: "#AAAAAA",
                        fontWeight: "700",
                      }}
                    >
                      Account
                    </InputLabel>
                    <Select
                      labelId="network-select-label"
                      id="network-select"
                      value={
                        accountData?.iban
                          ? accountData?.iban
                          : "Loading..." || ""
                      }
                      label="Select the account"
                      onChange={(event) =>
                        handleAccountChange(undefined, event)
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: isMobile
                              ? maxHeightActionsMobile
                              : maxHeightActions,
                            overflow: "auto",
                          },
                        },
                      }}
                    >
                      {accountsData?.map((account, idx) => (
                        <MenuItem key={idx} value={account.iban}>
                          {account.name.length > 25
                            ? `${account.name.slice(0, 25)}...`
                            : account.name}
                          , {account.iban}, {account.availableAmount} EUR
                        </MenuItem>
                      )) || []}
                    </Select>
                  </FormControl>
                </div>
                <div className={styles.buttonsContainer}>
                  <button
                    className={styles.continueBtn}
                    onClick={handleNextStep}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.modalContainer}>
          <div className={styles.sideSteps}>
            <div
              className={`${styles.stepRound} ${
                step < 2 ? styles.stepInactive : ""
              }`}
            >
              {step < 2 ? "2" : step > 2 ? <SuccessIcon /> : "2"}
            </div>
            <div className={styles.stepLine}></div>
          </div>
          <div className={styles.singleStep}>
            <div
              className={`${styles.stepTitle} ${
                step < 2 ? styles.textInactive : ""
              }`}
            >
              <h2>Step 2</h2>
              <p className="secondaryText" style={{ color: "#AAAAAA" }}>
                Wallet network
              </p>
            </div>
            <div className={step === 2 ? styles.activeStep : styles.step}>
              <div>
                <p className="primaryText" style={{ color: "#464646" }}>
                  Select the account you want to transfer money to. You can
                  select multiple accounts and different transfer amounts for
                  each account.
                </p>
              </div>
              <div className={styles.secondStepAmounts}>
                <div>
                  <p>Account balance</p>
                  <h1>€{accountBalance?.toFixed(2)}</h1>
                </div>
                <div>
                  <p>Transfer amount</p>
                  <h1>€{transferAmount?.toFixed(2)}</h1>
                </div>
                <div>
                  <p>Account balance after transfer</p>
                  <h1
                    style={{
                      color: balanceAfterTransfer > 0 ? " " : "#FF4D4D",
                    }}
                  >
                    €{balanceAfterTransfer.toFixed(2)}
                  </h1>
                </div>
              </div>
              <div className={styles.transferModalInputsContainer}>
                {fields.map((field, index) => {
                  const firstField = fields[0];
                  const accountData = firstField || {};

                  return (
                    <div
                      key={index}
                      className={styles.transferModalInput}
                      style={{ position: "relative" }}
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={{
                          maxHeight: "40px",
                          width: "100%",
                          marginBottom: "8px",
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                            padding: "0 3px",
                            color: "#AAAAAA",
                            fontWeight: "700",
                          },
                        }}
                      >
                        <InputLabel
                          id={`account-select-label-${index}`}
                          shrink
                          sx={{
                            backgroundColor: "white",
                            padding: "0px",
                          }}
                        >
                          Account #{index + 1}
                        </InputLabel>
                        <Select
                          labelId={`account-select-label-${index}`}
                          id={`account-select-${index}`}
                          value={field.iban || ""}
                          label="Select the account"
                          onChange={(event) =>
                            handleAccountChange(index, event)
                          }
                          sx={{
                            fontSize: "14px",
                            color: "#464646",
                            fontFamily: "Noto Sans, sans-serif",
                            "& .MuiMenuItem-root": {
                              fontFamily: "Noto Sans, sans-serif",
                            },
                          }}
                          displayEmpty
                          renderValue={(selectedIban) => {
                            if (!selectedIban) {
                              return (
                                <span
                                  style={{
                                    color: "#AAAAAA",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Select the account
                                </span>
                              );
                            }
                            const account = filteredTableData.find(
                              (acc) => acc.iban === selectedIban
                            );
                            if (!account) return selectedIban;

                            return `${account.name}, ${account.iban}, ${
                              account.balance || 0
                            } EUR`;
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: isMobile
                                  ? maxHeightActionsMobile
                                  : maxHeightActions,
                                overflow: "auto",
                              },
                            },
                          }}
                        >
                          {filteredTableData
                            ?.filter(
                              (account) =>
                                !selectedIbans.includes(account.iban) ||
                                account.iban === field.iban
                            )
                            .map((account, idx) => (
                              <MenuItem key={idx} value={account.iban}>
                                {account.name.length > 25
                                  ? `${account.name.slice(0, 25)}...`
                                  : account.name}
                                , {account.iban}, {account.balance || 0} EUR
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id={`amount-field-${index}`}
                        label="Transfer amount"
                        variant="outlined"
                        placeholder="Enter an amount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={field.value}
                        onChange={(event) => handleAmountChange(index, event)}
                        sx={{
                          maxHeight: "40px",
                          width: "100%",
                          marginBottom: "8px",
                          marginTop: "16px",
                          "& .MuiInputBase-root": {
                            height: "40px",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                            padding: "0 3px",
                            color: "#AAAAAA",
                            fontWeight: "700",
                          },
                          "& .MuiInputAdornment-root p": {
                            paddingLeft: "0 !important",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">€</InputAdornment>
                          ),
                          styles: {
                            fontSize: "15px",
                            fontWeight: 500,
                            width: "fitContent",
                            color: "#464646",
                          },
                        }}
                        // type="number"
                        step="any"
                      />
                      {fields.length > 1 && (
                        <button
                          variant="contained"
                          color="secondary"
                          onClick={() => removeField(index)}
                          className={styles.removeFieldButton}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              {balanceAfterTransfer < 0 ? (
                <p className={styles.validationP}>
                  The transfer amount is greater than the balance of the
                  original account. Top up the account or reduce the transfer
                  amount
                </p>
              ) : (
                ""
              )}

              <div className={styles.buttonsStepsContainer}>
                <div className={styles.buttonsStepsContainerLeft}>
                  <button
                    onClick={handleNextStep}
                    className={styles.continueBtn}
                  >
                    Confirm
                  </button>
                  <button
                    onClick={handlePreviousStep}
                    className={styles.backButton}
                  >
                    Back
                  </button>
                </div>
                {fields.length < 10 && (
                  <div className={styles.addAccountContainer}>
                    <button
                      onClick={addField}
                      className={styles.addFieldButton}
                    >
                      Add account <AddIcon />
                    </button>
                    <span className={styles.addAccountSpan}>
                      {fields?.length} of 10
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.closeButtonContainer}>
          <button
            className={styles.closeBtn}
            onClick={() => setTransferModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransferModal;
