const Rename = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#21A038"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9884 1.494C19.6718 0.81059 20.7799 0.810593 21.4633 1.49401L22.5061 2.53684C23.1895 3.22026 23.1895 4.3283 22.5061 5.01172L12.4267 15.0911C12.0048 15.513 11.4325 15.7501 10.8357 15.7501H9C8.58579 15.7501 8.25 15.4143 8.25 15.0001V13.1643C8.25 12.5676 8.48705 11.9953 8.90901 11.5733L18.9884 1.494ZM20.4026 2.55467C20.305 2.45704 20.1467 2.45704 20.0491 2.55467L18.808 3.7957L20.2044 5.19208L21.4455 3.95105C21.5431 3.85342 21.5431 3.69513 21.4455 3.5975L20.4026 2.55467ZM19.1438 6.25274L17.7474 4.85636L9.96967 12.634C9.82902 12.7747 9.75 12.9654 9.75 13.1643V14.2501H10.8357C11.0346 14.2501 11.2254 14.171 11.3661 14.0304L19.1438 6.25274Z"
          fill="#21A038"
        />
        <path
          d="M6.5 3.75012C4.98122 3.75012 3.75 4.98134 3.75 6.50012V17.5001C3.75 19.0189 4.98122 20.2501 6.5 20.2501H17.5C19.0188 20.2501 20.25 19.0189 20.25 17.5001V12.0001C20.25 11.5859 20.5858 11.2501 21 11.2501C21.4142 11.2501 21.75 11.5859 21.75 12.0001V17.5001C21.75 19.8473 19.8472 21.7501 17.5 21.7501H6.5C4.15279 21.7501 2.25 19.8473 2.25 17.5001V6.50012C2.25 4.15291 4.15279 2.25012 6.5 2.25012H12C12.4142 2.25012 12.75 2.58591 12.75 3.00012C12.75 3.41433 12.4142 3.75012 12 3.75012H6.5Z"
          fill="#21A038"
        />
      </svg>
    </>
  );
};

export default Rename;
