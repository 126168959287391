import React from "react";

const MobileAccountIcon = ({ fill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 14.8125C17.0177 14.8125 17.4375 14.3927 17.4375 13.875C17.4375 13.3572 17.0177 12.9375 16.5 12.9375C15.9822 12.9375 15.5625 13.3572 15.5625 13.875C15.5625 14.3927 15.9822 14.8125 16.5 14.8125Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0581 2.34869C14.5339 2.22275 15 2.58152 15 3.07373V4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5V3.07373C16.5 1.59711 15.1017 0.520785 13.6742 0.898637L4.76136 3.25793C3.281 3.64979 2.25 4.98922 2.25 6.52056V18.375C2.25 20.239 3.76104 21.75 5.625 21.75H18.375C20.239 21.75 21.75 20.239 21.75 18.375V17.0493C22.1984 16.79 22.5 16.3052 22.5 15.75V12C22.5 11.4448 22.1984 10.9601 21.75 10.7007V9.375C21.75 7.51103 20.239 6 18.375 6H6C5.58579 6 5.25 6.33578 5.25 6.75C5.25 7.16421 5.58579 7.5 6 7.5H18.375C19.4105 7.5 20.25 8.33948 20.25 9.375V10.5H16.125C14.261 10.5 12.75 12.011 12.75 13.875C12.75 15.739 14.261 17.25 16.125 17.25H20.25V18.375C20.25 19.4105 19.4105 20.25 18.375 20.25H5.625C4.58947 20.25 3.75 19.4105 3.75 18.375V6.52056C3.75 5.66981 4.32278 4.92569 5.1452 4.70799L14.0581 2.34869ZM16.125 12H21V15.75H16.125C15.0895 15.75 14.25 14.9105 14.25 13.875C14.25 12.8395 15.0895 12 16.125 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default MobileAccountIcon;
