import { useEffect, useState } from "react";
import {
  Checkbox,
  Button,
  Popover,
  Box,
  Text,
  ActionIcon,
} from "@mantine/core";
import { getAllAccountsAddMember } from "../../api/getAccounts";
import { IconX } from "@tabler/icons-react";
import {
  maxHeightActions,
  maxHeightActionsMobile,
  maxWidthActions,
} from "../../styles/constants";
import styles from "../../modules/Modals/ModalAddMember/styles.module.scss";
import useIsMobile from "../../hooks/useIsMobile";

const accountsPerPage = 100;
export const AccountSelect = ({ selectedAccounts, setSelectedAccounts }) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getAllAccountsAddMember(accountsPerPage);
        setAccounts(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleAccountSelection = (account) => {
    setSelectedAccounts((current) => {
      const isSelected = current.some((acc) => acc.ID === account.ID);
      if (isSelected) {
        return current.filter((acc) => acc.ID !== account.ID);
      }
      return [...current, account];
    });
  };

  const handleSelectAll = () => {
    if (selectedAccounts.length === accounts.length) {
      setSelectedAccounts([]);
    } else {
      setSelectedAccounts(accounts);
    }
  };

  const handleRemoveAccount = (accountId) => {
    setSelectedAccounts((current) =>
      current.filter((account) => account.ID !== accountId)
    );
  };

  return (
    <Box>
      <Popover
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
        position="bottom-start"
        withArrow
        width="fit-content"
        offset={10}
        trapFocus
      >
        <Popover.Target>
          <Button
            onClick={() => setPopoverOpened((o) => !o)}
            style={{
              color: "black",
              padding: "10px 20px",
              background: "transparent",
              border: "1px solid #23232340",
              borderRadius: "8px",
              marginBottom: selectedAccounts.length > 0 ? "20px" : "20px",
            }}
          >
            Add account +
          </Button>
        </Popover.Target>

        <Popover.Dropdown
          style={{
            maxHeight: isMobile ? maxHeightActionsMobile : "450px",
            overflowY: "auto",
          }}
        >
          <Checkbox
            label="All"
            checked={selectedAccounts.length === accounts.length}
            onChange={handleSelectAll}
            mb="sm"
            sx={{
              "& .mantine-Checkbox-input:checked": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                border: "none",
                cursor: "pointer",
              },
              "& .mantine-Checkbox-input:not(:checked)": {
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
              },
            }}
          />
          <div>
            {accounts.map((account, idx) => (
              <Checkbox
                key={`${account.ID}+${idx}`}
                label={`${account.name || "Unnamed"}, ${
                  account.iban || "No IBAN"
                }, ${account.availableAmount || 0} EUR`}
                checked={selectedAccounts.some((acc) => acc.ID === account.ID)}
                onChange={() => handleAccountSelection(account)}
                mb="xs"
                sx={{
                  "& .mantine-Checkbox-input:checked": {
                    background:
                      "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%)",
                    border: "none",
                    cursor: "pointer",
                  },
                  "& .mantine-Checkbox-input:not(:checked)": {
                    backgroundColor: "#f0f0f0",
                    cursor: "pointer",
                  },
                  "& .mantine-Checkbox-label": {
                    maxWidth: "350px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
            ))}
          </div>
        </Popover.Dropdown>
      </Popover>

      <div className={styles.containerWithAddedAccounts}>
        {selectedAccounts.map((account, idx) => (
          <div
            style={{
              marginBottom: "15px",
              textOverflow: "ellipsis",
              width: "fit-content",
            }}
            key={`account-${account.ID}+${idx}`}
          >
            <Box
              style={{
                backgroundColor: "#f0f0f0",
                borderRadius: "5px",
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                overflow: "auto",
                maxWidth: "100%",
                gap: "8px",
              }}
            >
              <Text size="sm" style={{ color: "#AAAAAA" }}>{`${
                account.name || "Unnamed"
              }, ${account.iban || "No IBAN"}, ${
                account.availableAmount || 0
              } EUR`}</Text>
              <ActionIcon
                size="xs"
                color="red"
                onClick={() => handleRemoveAccount(account.ID)}
              >
                <IconX size={14} style={{ color: "#AAAAAA" }} />
              </ActionIcon>
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
};
