import { useCallback, useEffect, useState } from "react";
import { SECONDS_TO_TESEND_2FA_CODE } from "../constants/constants";

const useTimeOutLine = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isCodeTimeout, setIsCodeTimeout] = useState(true);

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      setIsCodeTimeout(false);
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            setIsCodeTimeout(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  const startTimer = useCallback(() => {
    setTimeLeft(SECONDS_TO_TESEND_2FA_CODE);
  }, []);

  return { timeLeft, isCodeTimeout, startTimer };
};

export default useTimeOutLine;
