import { axiosAuth } from "./axios";
import { API_PATHS } from "./constants";

export const getPayments = async ({ page, perPage, params, cardId }) => {
  const response = await axiosAuth.get(API_PATHS.PAYMENTS, {
    params: {
      ...params,
      page: page,
      perPage: perPage,
      ...(cardId && { cardId }),
    },
  });

  return response.data;
};

export const getExportPayments = async ({ params }) => {
  return await axiosAuth.get(API_PATHS.EXPORT_PAYMENTS, {
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
    params: {
      ...params,
    },
  });
};
