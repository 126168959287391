import { Box } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { optionsTable, pageSize } from "../../../constants/constants";
import Skeleton from "../../../components/Skeleton/Skeleton";
import { getCodes } from "../../../api/metapayApi";
import { useAuthQuery } from "../../../hooks/useAuthQuery";
import { useQueryClient } from "@tanstack/react-query";
import ColumnsTreeDsCodes from "../../../constants/columns/ColumnsTreeDsCodes";
import styles from "./styles.module.scss";

const ThreeDSTable = () => {
  const queryClient = useQueryClient();
  const [dataTable, setDataTable] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
  });

  const {
    data: cardsDataInfo,
    isLoading,
    isFetching,
  } = useAuthQuery({
    queryKey: ["threeDSCodes", pagination.pageIndex, pageSize],
    queryFn: () =>
      getCodes({
        page: pagination.pageIndex + 1,
        perPage: pageSize,
        type: "otp",
      }),
    keepPreviousData: true,
    staleTime: 4 * 60 * 1000,
    cacheTime: 4 * 60 * 1000,
    onSuccess: (data) => {
      if (data.meta.current_page < data.meta.last_page) {
        queryClient.prefetchQuery({
          queryKey: ["threeDSCodes", pagination.pageIndex + 2, pageSize],
          queryFn: () =>
            getCodes({
              page: pagination.pageIndex + 2,
              perPage: pageSize,
              type: "otp",
            }),
        });
      }
    },
  });

  const cardsData = cardsDataInfo?.data;

  useEffect(() => {
    if (cardsData && !isLoading && !isFetching) {
      setDataTable(cardsData);
    }
  }, [cardsData, isLoading, isFetching]);

  if (isLoading || isFetching)
    return <Skeleton className={styles.skeletonTable} isSpinner={true} />;

  return (
    <Box
      style={{
        borderRadius: "8px",
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Table
        columns={ColumnsTreeDsCodes()}
        data={dataTable}
        enablePagination={true}
        manualPagination={true}
        onPaginationChange={(updater) => {
          setPagination((prev) => {
            const newState =
              typeof updater === "function" ? updater(prev) : updater;
            return newState;
          });
        }}
        state={{
          pagination: {
            pageIndex: pagination.pageIndex,
            pageSize: pageSize,
          },
        }}
        enableColumnActions={false}
        rowCount={cardsDataInfo?.meta?.total ?? 0}
        pageCount={
          cardsDataInfo?.meta?.last_page ??
          Math.ceil((cardsDataInfo?.meta?.total ?? 0) / pageSize)
        }
        enableColumnFilters={true}
        enableGlobalFilter={false}
        enableHiding={true}
        enableTopToolbar={false}
        enableDensityToggle={false}
        paginationDisplayMode={"pages"}
        mantinePaginationProps={{
          showRowsPerPage: false,
        }}
        mantineTableProps={{
          ...optionsTable,
        }}
      />
    </Box>
  );
};
export default ThreeDSTable;
