import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import QuestionIcons from "../../icons/QuestionIcons";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";
import CopyIcon from "../../icons/CopyIcon";
import { toast } from "react-toastify";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { getCorrectCardType } from "../../utils/getCorrectCardType";
import CardNumber from "../../components/CardNumber/CardNumber";

const ColumnsMetapayCodes = () => {
  const columns = [
    {
      accessorKey: "createdAt",
      header: "DATE",
      enableSorting: false,

      Cell: ({ cell }) => {
        const formattedDate =
          cell.getValue() !== null ? cell.getValue() : "No date";
        return <DateFormatter date={formattedDate} />;
      },
    },
    {
      accessorKey: "cardNumber",
      header: "CARD NUMBER",
      enableSorting: false,
      accessorFn: (row) => (row.cardMask !== null ? row.cardMask : "No info"),

      Cell: ({ cell, row }) => {
        const cardNum = cell.getValue() !== null ? cell.getValue() : "No info";
        const paymentSystem = row.original.cardType;

        const cardId = row.original.cardId;
        const navigate = useNavigate();

        const handleNavigateToCard = () => {
          navigate(`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`);
        };

        return (
          <div className={styles.containerCardName}>
            {getCorrectCardType(paymentSystem)}
            <Link
              style={{ display: "flex" }}
              to={`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`}
            >
              <CardNumber cardNum={cardNum} onClick={handleNavigateToCard} />
            </Link>

            <CustomTooltip
              placement="bottom-start"
              title="Click to see card details "
              arrow
            >
              <span className={styles.containerQuestion}>
                <QuestionIcons />
              </span>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      accessorKey: "cardName",
      header: "CARD NAME",
      enableSorting: false,

      Cell: ({ cell, row }) => {
        const cardId = row.original.cardId;

        return (
          <Link
            className={styles.containerUserName}
            to={`${PAGE_ROUTES.CARD_DETAILES.replace(":id", cardId)}`}
          >
            {cell.getValue()}
          </Link>
        );
      },
    },

    {
      accessorKey: "amount",
      enableSorting: false,
      header: "AMOUNT",
      Cell: ({ cell, row }) => {
        const amount = cell.getValue() !== null ? cell.getValue().amount : 0;
        return <span>{amount} €</span>;
      },
    },
    {
      accessorKey: "description",
      enableSorting: false,

      header: "PAYMENT DETAILS",
    },
    {
      accessorKey: "code",
      header: "METAPAY CODE",
      enableSorting: false,

      Cell: ({ cell }) => {
        const code = cell.getValue();
        const handleCopyToClipboard = async () => {
          try {
            await navigator.clipboard.writeText(code);
            toast.success("Copied to clipboard!");
          } catch (err) {
            toast.error("Failed to copy!");
            console.error("Failed to copy text: ", err);
          }
        };

        return (
          <div className={styles.containerCodes}>
            <span>{code}</span>
            <CopyIcon
              className={styles.copyIcon}
              onClick={handleCopyToClipboard}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};

export default ColumnsMetapayCodes;
