import { useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "../../components/Button/Button";
import useIsMobile from "../../hooks/useIsMobile";

const ServiceItem = ({
  img,
  title,
  text,
  linkSpan,
  serviceIcon,
  aLink,
  buttonText,
  buttonLink,
}) => {
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();

  return (
    <div className={styles.serviceItem}>
      <div className={styles.text}>
        <div className={styles.header}>
          <div
            className={styles.imageSceleton}
            style={{ display: loading ? "block" : "none" }}
          ></div>
          <img
            src={img}
            alt={title}
            onLoad={() => setLoading(false)}
            style={{
              display: loading ? "none" : "block",
              height: isMobile ? "64px" : "82px",
            }}
          />
          <h1 className={styles.titleText}>{title}</h1>
        </div>
      </div>
      <div className={styles.textContainer}>
        <p>{text}</p>
      </div>

      <div className={styles.serviceItemLinks}>
        <a href={buttonLink} target="_blank" rel="noreferrer">
          <Button className={styles.button}>{buttonText}</Button>
        </a>
        <div className={styles.serviceItemLink}>
          <a
            href={aLink}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            {linkSpan}
          </a>
          {serviceIcon && <img src={serviceIcon} alt="img" />}
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
