import React from "react";
import { Link } from "react-router-dom";
import { PAGE_ROUTES } from "../../pages/constants";
import logoImg from "../../images/logo.png";

import profileIcon from "../../icons/mobile/Profile.svg";
import styles from "../../modules/MainLayout/MainLayout.module.scss";

const MobileHeader = () => {
  return (
    <div className={styles.headerMobile}>
      <div className={styles.containerHeaderMobile}>
        <Link to={PAGE_ROUTES.DASHBOARD}>
          <img
            src={logoImg}
            alt="logo"
            style={{ width: "fitContent" }}
            height={31}
          />
        </Link>

        <Link to={PAGE_ROUTES.PROFILE}>
          <img src={profileIcon} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default MobileHeader;
