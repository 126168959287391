export const getOperationType = (operationType) => {
  const transformedOperation = operationType
    ? operationType
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase())
    : "";

  return transformedOperation === "Card Issue Fee"
    ? "Payment for card issue"
    : transformedOperation;
};
