import ItemBlockInfo from "../../components/itemBlockInfo/ItemBlockInfo";
import { PAGE_ROUTES } from "../../pages/constants";
import { HIGHLIGH_POSITION_LAST } from "../../constants/constants";
import styles from "./styles.module.scss";
import React from "react";
import threeCardsInARow from "../../images/ThreeCardInARow.png";
import clsx from "clsx";
import Skeleton from "../../components/Skeleton/Skeleton";
import DashBoardTable from "../Tables/DashBoard/DashBoardTable";
import NewsContainer from "../../components/news/NewsContainer";
import { format } from "date-fns";
import useIsMobile from "../../hooks/useIsMobile";

const DashBoardItems = ({ data, isLoading, isFetching }) => {
  const dashboardData = data?.data?.data || {};
  const currentMonth = format(new Date(), "MMMM").toUpperCase();
  const isMobile = useIsMobile();

  return (
    <>
      <div className={styles.containerDashboard}>
        <div className={styles.containerWidgets}>
          {isMobile && (
            <ItemBlockInfo
              title="AVAILABLE"
              titleColored="BALANCE"
              additContent={
                isLoading || isFetching ? (
                  <Skeleton className={styles.widgetSkeleton} />
                ) : (
                  <div className="H1">
                    €
                    {dashboardData?.totalBalanceAmount?.value
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                  </div>
                )
              }
              linkTitle="Details"
              href={`${PAGE_ROUTES.ACCOUNTS}`}
              highlightPosition={HIGHLIGH_POSITION_LAST}
            />
          )}
          <ItemBlockInfo
            title="TOTAL SPEND FOR"
            titleColored="TODAY"
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeleton} />
              ) : (
                <div className="H1">
                  €{dashboardData.totalSpendToday?.value || 0}
                </div>
              )
            }
            linkTitle="Details"
            href={`${PAGE_ROUTES.PAYMENTS}?spend-for-day=true`}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
          <ItemBlockInfo
            title="TOTAL SPEND FOR"
            titleColored={currentMonth}
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeleton} />
              ) : (
                <div className="H1">
                  €{dashboardData.totalSpendMonth?.value || 0}
                </div>
              )
            }
            linkTitle="Details"
            href={`${PAGE_ROUTES.PAYMENTS}?spend-for-month=true`}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
          <ItemBlockInfo
            title="CARDS ISSUED TODAY"
            titleColored=""
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeletonWithCard} />
              ) : (
                <div className="H1">
                  <div className={styles.containerImgCard}>
                    <p>
                      {dashboardData.cardsIssuedToday?.value || 0}{" "}
                      <span className={clsx("body2", styles.cardText)}>
                        Cards
                      </span>
                    </p>
                    <img src={threeCardsInARow} alt="" height={40} />
                  </div>
                </div>
              )
            }
            linkTitle="All cards"
            href={`${PAGE_ROUTES.CARDS}?issued-today=true`}
          />
          <ItemBlockInfo
            title="ALL ACTIVE CARDS"
            titleColored=""
            additContent={
              isLoading || isFetching ? (
                <Skeleton className={styles.widgetSkeletonWithCard} />
              ) : (
                <div className="H1">
                  <div className={styles.containerImgCard}>
                    <div className={styles.conainerAllCards}>
                      {dashboardData.allValidCards?.value || 0}
                      <div className={styles.twoRowsText}>
                        <span className={clsx("body2", styles.cardText)}>
                          All active
                        </span>
                        <span className={clsx("body2", styles.cardText)}>
                          team cards
                        </span>
                      </div>
                    </div>
                    {/* <CardInRowThree /> */}
                    <img src={threeCardsInARow} alt="" height={40} />
                  </div>
                </div>
              )
            }
            linkTitle="All cards"
            href={PAGE_ROUTES.CARDS}
            highlightPosition={HIGHLIGH_POSITION_LAST}
          />
          <NewsContainer
            data={data}
            isLoading={isLoading}
            isFetching={isFetching}
            width={1900}
          />
        </div>
        <div className={styles.containerTableDashboard}>
          <DashBoardTable />
        </div>
      </div>
    </>
  );
};

export default React.memo(DashBoardItems);
