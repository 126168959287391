import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9952 1.50293C5.75281 1.50293 1.50293 5.7528 1.50293 10.9953C1.50293 16.2377 5.75281 20.4876 10.9952 20.4876C13.3465 20.4876 15.498 19.6328 17.1561 18.2169L21.4822 22.5429C21.7751 22.8358 22.2499 22.8358 22.5428 22.5429C22.8357 22.2501 22.8357 21.7752 22.5428 21.4823L18.2168 17.1563C19.6328 15.4982 20.4876 13.3466 20.4876 10.9953C20.4876 5.7528 16.2377 1.50293 10.9952 1.50293ZM3.00293 10.9953C3.00293 6.58123 6.58123 3.00293 10.9952 3.00293C15.4093 3.00293 18.9876 6.58123 18.9876 10.9953C18.9876 15.4093 15.4093 18.9876 10.9952 18.9876C6.58123 18.9876 3.00293 15.4093 3.00293 10.9953Z"
        fill="#AAAAAA"
      />
    </svg>
  );
};

export default SearchIcon;
