import React from "react";
import styles from "./styles.module.scss";
import { Button } from "../../../components/Button/Button";
import { Select } from "@mantine/core";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { getAllCardBins } from "../../../api/cardsApi";
import DataPicker from "../../../components/DatePicker/DataPicker";
import { getExchangeRate } from "../../../api/getAccounts";
import { getPaymentSystemLabel } from "../../../utils/getPaymentSystem";

const binsPerPage = 100;

const TableFiltersAllCards = ({ filters, setFilters }) => {
  const queryClient = useQueryClient();
  const filtersData = useQueries({
    queries: [
      {
        queryKey: ["allBinsCard", binsPerPage],
        queryFn: () => getAllCardBins({ perPage: binsPerPage }),
        keepPreviousData: true,
      },
      {
        queryKey: ["allIbansCard"],
        queryFn: getExchangeRate,
        keepPreviousData: true,
      },
    ],
  });

  const bins = filtersData[0]?.data?.data || [];
  const ibans = filtersData[1]?.data?.data?.data?.accounts;

  const isLoading = filtersData.some((query) => query.isLoading);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      dateFrom: null,
      dateTo: null,
      status: null,
      iban: null,
      bin: null,
    });
    setTimeout(() => handleUpdateCardsData(), 0);
  };
  const handleUpdateCardsData = () => {
    queryClient.invalidateQueries(["tableData"]);
  };

  const handleDateChange = (value) => {
    const formatToISODate = (date) =>
      date
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : null;

    if (Array.isArray(value) && value.length === 2) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: formatToISODate(value[0]),
        dateTo: formatToISODate(value[1]),
      }));
    } else if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: formatToISODate(value),
        dateTo: null,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateFrom: null,
        dateTo: null,
      }));
    }
  };

  return (
    <div className={styles.containerFilters}>
      <div className={styles.containerWithItemsFiltering}>
        <DataPicker
          mode="range"
          className={styles.item}
          label="Creation date"
          placeholder={"For the whole period"}
          value={
            filters.dateFrom && filters.dateTo
              ? [new Date(filters.dateFrom), new Date(filters.dateTo)]
              : filters.dateFrom
              ? [new Date(filters.dateFrom), null]
              : null
          }
          onChange={handleDateChange}
        />

        <Select
          label="Account"
          value={filters.ibanId || null}
          onChange={(selectedId) => {
            const selectedIbanObj = ibans?.find(
              (item) => item.ID === selectedId
            );
            if (selectedIbanObj) {
              handleFilterChange("ibanId", selectedIbanObj.ID);
              handleFilterChange("iban", selectedIbanObj.iban);
            } else {
              handleFilterChange("ibanId", null);
              handleFilterChange("iban", null);
            }
          }}
          placeholder={isLoading ? "Loading..." : "All"}
          data={
            ibans?.map((item) => ({
              value: item.ID,
              label: `${item.name}, ${item.iban}, ${
                item?.balance === 0 ? item?.balance : item?.balance?.toFixed(2)
              } EUR`,
            })) || []
          }
          className={styles.item}
          disabled={isLoading}
          style={{ fontFamily: "Noto Sans" }}
          styles={{
            input: {
              "&:focus-within": {
                borderColor: "rgb(33, 160, 56)",
                borderWidth: "1px",
              },
            },
            item: {
              "&[data-hovered]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
                borderRadius: "4px",
              },
              "&[data-selected]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
              },
            },
          }}
        />
        <Select
          label="BIN"
          value={filters.bin}
          onChange={(value) => handleFilterChange("bin", value)}
          placeholder={isLoading ? "Loading..." : "All BINs"}
          data={bins.map((bin) => ({
            value: bin.binNumber,
            label: `${bin.binNumber} (${getPaymentSystemLabel(
              bin.paymentSystem
            )})`,
          }))}
          className={styles.item}
          disabled={isLoading}
          styles={{
            input: {
              "&:focus-within": {
                borderColor: "rgb(33, 160, 56)",
                borderWidth: "1px",
              },
            },
            item: {
              "&[data-hovered]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
                borderRadius: "4px",
              },
              "&[data-selected]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
              },
            },
          }}
        />
        <Select
          label="Status"
          value={(() => {
            const reverseStatusMap = {
              "00": "active",
              50: "pending",
              "03": "stopped",
              21: "closed",
            };
            return reverseStatusMap[filters.status] || null;
          })()}
          onChange={(value) => {
            const statusMap = {
              active: "00",
              pending: "50",
              stopped: "03",
              closed: "21",
            };
            handleFilterChange("status", statusMap[value] || null);
          }}
          placeholder="All"
          data={[
            { value: "active", label: "Active" },
            { value: "pending", label: "Pending" },
            { value: "stopped", label: "Stopped" },
            { value: "closed", label: "Closed" },
          ]}
          className={styles.item}
          styles={{
            input: {
              "&:focus-within": {
                borderColor: "rgb(33, 160, 56)",
                borderWidth: "1px",
              },
            },
            item: {
              "&[data-hovered]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
                borderRadius: "4px",
              },
              "&[data-selected]": {
                background:
                  "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                color: "#fff",
              },
            },
          }}
        />
      </div>

      <div className={styles.containerButtonsFilter}>
        <Button className={styles.applyFilters} onClick={handleUpdateCardsData}>
          Apply filters
        </Button>
        <Button className={styles.clearFilters} onClick={clearFilters}>
          Clear filters
        </Button>
      </div>
    </div>
  );
};

export default TableFiltersAllCards;
