import React from "react";

const ExitIcon = ({ onClick, styles }) => {
  return (
    <svg
      onClick={onClick}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles}
    >
      <g clipPath="url(#clip0_88_9629)">
        <path
          d="M19.3943 5.10572C19.6872 5.3986 19.6872 5.87349 19.3943 6.16638L13.5607 12L19.3943 17.8336C19.6872 18.1265 19.6872 18.6014 19.3943 18.8943C19.1014 19.1872 18.6265 19.1872 18.3336 18.8943L12.5 13.0607L6.66637 18.8943C6.37349 19.1872 5.89859 19.1872 5.60571 18.8943C5.31283 18.6014 5.31283 18.1265 5.60571 17.8336L11.4393 12L5.60571 6.16638C5.31282 5.87349 5.31283 5.3986 5.60571 5.10572C5.89859 4.81283 6.37348 4.81283 6.66637 5.10572L12.5 10.9393L18.3336 5.10572C18.6265 4.81283 19.1014 4.81283 19.3943 5.10572Z"
          fill="#232323"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_9629">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExitIcon;
