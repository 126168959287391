import axios from "axios";
import eventEmitter from "../utils/emitter/EventEmitter";
const BASE_URL = "https://fbm-pay.com";

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosPublic.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAuth.interceptors.request.use(
  async (config) => {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    // return config;
    let token = localStorage.getItem("token");

    if (!token) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      token = localStorage.getItem("token");

      if (!token) {
        return Promise.reject(new Error("No token available"));
      }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const token = localStorage.getItem("token");
      if (token) {
        eventEmitter.emit("logout");
      }
    }
    return Promise.reject(error);
  }
);

// const checkTokenValidity = async (token) => {
//   try {
//     const response = await axiosPublic.post("/auth/validate-token", { token });
//     return response.data.isValid;
//   } catch (error) {
//     return false;
//   }
// };

// axiosAuth.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response) {
//       const token = localStorage.getItem("token");

//       if (token && error.response.status === 401) {
//         try {
//           // Отправляем запрос на проверку токена
//           const isTokenValid = await checkTokenValidity(token);
//           if (!isTokenValid) {
//             localStorage.removeItem("token"); // Удаляем токен
//             eventEmitter.emit("logout"); // Разлогиниваем
//           }
//         } catch (err) {
//           // Если проверка токена завершилась ошибкой, разлогиниваем
//           localStorage.removeItem("token");
//           eventEmitter.emit("logout");
//         }
//       }

//       if (error.response.status === 403) {
//         eventEmitter.emit("ban");
//       }
//     }
//     return Promise.reject(error);
//   }
// );
