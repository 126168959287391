import React from "react";

const ManagerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13C8.68629 13 6 10.3137 6 7ZM12 2.5C9.51472 2.5 7.5 4.51472 7.5 7C7.5 9.48528 9.51472 11.5 12 11.5C14.4853 11.5 16.5 9.48528 16.5 7C16.5 4.51472 14.4853 2.5 12 2.5Z"
        fill="#232323"
      />
      <path
        d="M8.22287 15.3013C8.33327 15.7005 8.09912 16.1136 7.69989 16.224C6.59584 16.5293 5.61213 17.0121 4.9088 17.7519C4.22237 18.4739 3.75 19.4954 3.75 20.9998C3.75 21.414 3.41421 21.7498 3 21.7498C2.58579 21.7498 2.25 21.414 2.25 20.9998C2.25 19.1442 2.84796 17.7426 3.82169 16.7183C4.77853 15.7119 6.04482 15.1254 7.30011 14.7783C7.69934 14.6679 8.11247 14.902 8.22287 15.3013Z"
        fill="#232323"
      />
      <path
        d="M15.7773 15.3013C15.8877 14.902 16.3009 14.6679 16.7001 14.7783C17.9554 15.1254 19.2217 15.7119 20.1785 16.7183C21.1522 17.7426 21.7502 19.1442 21.7502 20.9998C21.7502 21.414 21.4144 21.7498 21.0002 21.7498C20.586 21.7498 20.2502 21.414 20.2502 20.9998C20.2502 19.4954 19.7778 18.4739 19.0914 17.7519C18.3881 17.0121 17.4044 16.5293 16.3003 16.224C15.9011 16.1136 15.6669 15.7005 15.7773 15.3013Z"
        fill="#232323"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4418 14C9.75077 14 9.21147 14.6232 9.33604 15.3183C9.43627 15.8776 9.54868 16.3712 9.76352 16.8333C9.92518 17.1809 10.1339 17.4881 10.4056 17.7911L9.13075 22.0719C8.84433 23.0337 9.56483 24 10.5684 24H13.4316C14.4351 24 15.1556 23.0337 14.8692 22.0719L13.5949 17.7927C13.8478 17.5095 14.0506 17.2114 14.2129 16.872C14.4362 16.4055 14.5659 15.8961 14.6692 15.3102C14.7923 14.6116 14.2463 14 13.5649 14H10.4418ZM11.1237 16.2008C11.0347 16.0095 10.9663 15.7914 10.901 15.5H13.1011C13.0318 15.7963 12.9546 16.0265 12.8599 16.2246C12.7269 16.5024 12.543 16.7507 12.2384 17.0349L11.9977 17.2594L11.7669 17.0399C11.4269 16.7166 11.2465 16.4649 11.1237 16.2008ZM12 17.6926L13.4316 22.5H10.5684L12 17.6926Z"
        fill="#222222"
      />
    </svg>
  );
};

export default ManagerIcon;
