export const API_PATHS = {
  USER_DATA: "/api/auth/me",
  CHANGE_USER_DATA: "/api/users",
  ACCOUNTS: "/api/accounts",
  FUND_ACCOUNT: "api/accounts/fund",
  ACCOUNTS_OPERATIONS: "api/accounts/operations",
  EXCHANGE_RATE: "api/accounts/options",
  TRANSFER_AMOUNT: "api/accounts/payment",
  LOGIN: "/api/auth/login",
  CHECK_2FA_CODE: "/api/auth/check-code",
  SEND_2FA_CODE_AGAIN: "/api/auth/code",
  LOGOUT_USER: "/api/auth/logout",
  CHANGE_PASSWORD: "/api/users/change-password",
  SET_NEW_PASSWORD: "api/auth/set-new-password",
  SEND_RECOVER_CODE: "/api/auth/reset-password",
  ALL_NEWS: "/api/news",
  DASHBOARD_WIDGETS_VALUES: "/api/widgets/dashboard",
  ALL_TEAM_MEMBERS: "/api/users",
  CREATE_USER: "/api/users/create",
  CREATE_ACCOUNT: "api/accounts",

  //excel
  ALL_ACCOUNTS_EXCEL: "#accounts-GETapi-accounts-export",
  ALL_CARDS_EXCEL: "#accounts-GETapi-cards-export",
  PAYMENNTS_EXCEL: "#accounts-GETapi-payments-export",

  //artem payments excel:
  EXCEL_PAYMENTS: "/api/accounts/export",
  EXCEL_BALANCE_HISTORY: "api/accounts/operations/export",
  //cards
  ALL_CARDS: "/api/cards",
  CARDS_BINS: "api/cards/bins",
  CARDS_IBANS: "api/cards/options",
  CARDS_OPTIONS: "api/cards/options",
  EXPORT_CARDS: "/api/cards/export",
  //payments
  PAYMENTS: "/api/payments",
  EXPORT_PAYMENTS: "api/payments/export",
  BALANCE_HISTORY_EXPORT: "/api/accounts/operations/export",
  //metapays
  METAPAYS_CODES: "/api/cards/codes",

  NOTIFICATIONS: "/api/notifications",
};
