import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import style from "./styles.module.scss";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export const formatter = (date, withTime, onlyTime = false, userTimeZone) => {
  if (!date) return "";

  let formattedDate = "";

  if (date) {
    const dateObj = parseISO(date);

    // Корректируем дату на основе временной зоны пользователя
    const zonedDate = toZonedTime(dateObj, userTimeZone);

    if (onlyTime) {
      formattedDate = format(zonedDate, "HH:mm");
    } else {
      const formatTime = withTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";
      formattedDate = format(zonedDate, formatTime);
    }
  }
  return formattedDate;
};

export const DateFormatter = ({
  date,
  className,
  loading,
  withTime = true,
  onlyTime = false,
}) => {
  const { userTimeZone } = useContext(AuthContext);

  return (
    <p className={className}>
      {loading ? (
        <div className={style.textSkeleton}></div>
      ) : (
        formatter(date, withTime, onlyTime, userTimeZone)
      )}
    </p>
  );
};
