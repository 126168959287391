import { Button } from "../../../components/Button/Button";
import AlarmIcon from "../../../icons/AlarmIcon";
import Modal from "../Modal";
import SetLimitsCard from "../SetLimitsCard/SetLimitsCard";
import styles from "./styles.module.scss";

export const ModalsInteractive = ({
  activeModal,
  modalData,
  onSubmit,
  onCancel,
  isCommonTxt = true,
  children,
  idForm,
  buttonTitleSubmit,
}) => {
  let title, subTitle, message, confirmLabel, questions, icon, customContent;
  if (!activeModal) return null;

  if (isCommonTxt) {
    const modalsContent = {
      blockUser: {
        title: "blockUser", //logic key modal action
        subTitle: "Block user",
        message: `User ${modalData?.userName || "No name"} (${
          modalData?.userEmail || "No email"
        }) will be blocked and forcibly logged out from their account.`,
        questions: "Are you sure you want to block this user?",
        confirmLabel: "Block",
      },
      unblockUser: {
        title: "unblockUser", //logic key modal action
        subTitle: "Unblock User",
        message: `User ${modalData?.userName || "No name"} (${
          modalData?.userEmail || "No email"
        }) will be unblocked and able to log into their account again.`,
        questions: "Are you sure you want to unblock this user?",
        confirmLabel: "Unblock",
      },
      removeAccount: {
        title: "removeAccount", //logic key modal action
        subTitle: "Remove account",
        message: (
          <>
            User {modalData?.userName || "No name"} (
            {modalData?.userEmail || "No email"}) will lose access{" "}
            <strong>
              to this account: "{modalData?.accountName || "No acc. name"}" and
              all its cards.
            </strong>
          </>
        ),
        questions: "Are you sure you want to remove this account?",
        confirmLabel: "Remove",
      },
      grantAccount: {
        title: "grantAccount", //logic key modal action
        subTitle: "Grant access",
        message: `User ${modalData?.nameAccount || "No acc. name"} (${
          modalData?.email || "No email"
        }) will gain access to the account "${
          modalData?.userGranted || "No user name"
        }".`,
        questions: "Are you sure you want to grant access?",
        confirmLabel: "Grant Access",
      },
      removeCardUser: {
        title: "removeCardUser", //logic key modal action
        subTitle: "Remove user",
        message: `User ${modalData?.userName || "No name"} (${
          modalData?.userEmail || "No email"
        })  will lose access to this account "${
          modalData?.accountName || "No name"
        }" and all its cards also.`,
        questions: "Are you sure you want to remove this user?",
        confirmLabel: "Remove",
      },

      holdCard: {
        title: "holdCard", //logic key modal action
        subTitle: "Hold сard ",
        message: `All selected cards will be held and payments will be suspended. Are you sure you want to hold the cards?`,
        questions: "",
        confirmLabel: "Hold",
      },
      unholdCard: {
        title: "unholdCard", //logic key modal action
        subTitle: "Unhold сard",
        message: `All selected cards will be unhold and payments will become available. Are you sure you want to unhold the cards?`,
        questions: "",
        confirmLabel: "Unhold",
      },
      activateThreeDS: {
        title: "activateThreeDS", //logic key modal action
        subTitle: "Activate 3DS",
        message: `3DS OTP codes will be activated for all selected cards. Are you sure you want to activate 3DS?`,
        questions: "",
        confirmLabel: "Activate",
      },
      deactivateThreeDS: {
        title: "deactivateThreeDS", //logic key modal action
        subTitle: "Deactivate 3DS",
        message: `3DS OTP codes will be deactivated for all selected cards. Are you sure you want to deactivate 3DS?`,
        questions: "",
        confirmLabel: "Deactivate",
      },
      closeCard: {
        title: "closeCard", //logic key modal action
        subTitle: "Attention",
        icon: <AlarmIcon />,
        message: `If you close сard, you will not be able to restore it.`,
        questions: "Are you sure you want to do this?",
        confirmLabel: "Close",
      },
      blockCard: {
        icon: <AlarmIcon />,
        title: "blockCard", //logic key modal action
        subTitle: "Attention",
        message: `If you close сard, you will not be able to restore it.`,
        questions: "Are you sure you want to do this?",
        confirmLabel: "Close",
      },
    };

    ({
      title,
      subTitle,
      message,
      confirmLabel,
      questions,
      icon = null,
      customContent = null,
    } = modalsContent[activeModal]);
  }

  return (
    <Modal opened onClose={onCancel} title={title}>
      <div className={styles.contentModal}>
        {isCommonTxt && !customContent ? (
          <div className={styles.containerText}>
            <div className={styles.containerIconInteractiveModal}>
              {icon && icon}
              <p className="H1">{subTitle}</p>
            </div>

            <div className={styles.containerSubtitleText}>
              <span className={styles.subTitle}>{message}</span>
              <span style={{ textAlign: "center" }}>{questions}</span>
            </div>
          </div>
        ) : (
          customContent
        )}

        <div className={styles.containerButtons}>
          <Button
            type={isCommonTxt ? "button" : "submit"}
            onClick={(e) => {
              if (isCommonTxt) {
                e.preventDefault();
                onSubmit?.(activeModal, modalData);
              }
            }}
            form={isCommonTxt ? undefined : idForm}
            className={styles.activeBtn}
            style={{
              background:
                title === "closeCard" || title === "blockCard" ? "#FF4D4D" : "",
            }}
          >
            {isCommonTxt ? confirmLabel : buttonTitleSubmit}
          </Button>

          <Button
            variant="outline"
            onClick={onCancel}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
