import styles from "./styles.module.scss";
import ReloadIcon from "../../icons/ReloadIcon";
import { Table, Pagination, Select, Button, Group } from "@mantine/core";
import Paginations from "../../components/pagination/Pagination";
import React, { useCallback, useEffect, useState } from "react";
import { useAuthQuery } from "../../hooks/useAuthQuery";
import { getExportPayments, getPayments } from "../../api/paymentsApi";
import Skeleton from "../../components/Skeleton/Skeleton";
import { useQueryClient } from "@tanstack/react-query";
import StatusBadge from "./components/StatusBadge";
import ArrowLink from "../../icons/ArrowLink";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import QestionIcon from "../../icons/QestionIcon";
import ExcelButton from "../../components/ExcelButton/ExcelButton";
import { getPayemntsData } from "../../api/getExcel";
import { IconChevronLeftPipe, IconChevronRightPipe } from "@tabler/icons-react";
import { getCorrectCardType } from "../../utils/getCorrectCardType";
import DatePicker from "../../components/DatePicker/DatePickerInput";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import { getExchangeRate } from "../../api/getAccounts";
import { getCardsOptions } from "../../api/cardsApi";
import dayjs from "dayjs";
import { handleExcelDownload } from "../../utils/excelDownload";
import CardNumber from "../../components/CardNumber/CardNumber";
import { PAGE_ROUTES } from "../constants";
import { maxWidthColumns } from "../../styles/constants";
import { DateFormatter } from "../../components/DateFormatter/DateFormatter";

const Payments = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0 });
  const pageSize = 15;
  const location = useLocation();
  const [isActiveFilters, setIsActiveFilters] = useState(false);
  const [dateValue, setDateValue] = useState([null, null]);
  const [accountsFiltersData, setAccountsFilterData] = useState();
  const [cardsFiltersData, setCardsFiltersData] = useState();
  const [filters, setFilters] = useState({
    cardId: null,
    dateFrom: null,
    dateTo: null,
    type: null,
    iban: null,
    accountId: null,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const today = dayjs().startOf("day");

    if (searchParams.get("spend-for-day")) {
      setFilters((prev) => ({
        ...prev,
        dateFrom: today.toDate(),
        dateTo: today.toDate(),
      }));
      setDateValue([today.toDate(), today.toDate()]);
    } else if (searchParams.get("spend-for-month")) {
      const startOfMonth = dayjs().startOf("month");
      const endOfMonth = dayjs().endOf("month");

      setFilters((prev) => ({
        ...prev,
        dateFrom: startOfMonth.toDate(),
        dateTo: endOfMonth.toDate(),
      }));
      setDateValue([startOfMonth.toDate(), endOfMonth.toDate()]);
      setTimeout(() => {
        handleUpdateAData();
      }, 1000);
    }

    const iban = searchParams.get("iban");

    if (iban) {
      setFilters((prev) => ({
        ...prev,
        iban: iban,
      }));
      setTimeout(() => {
        handleUpdateAData();
      }, 1000);
    }
  }, [location]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setPagination({ pageIndex: 0 });
  };

  const handleClearFilters = () => {
    setDateValue([null, null]);
    setPagination({ pageIndex: 0 });

    setFilters({
      cardId: null,
      dateFrom: null,
      dateTo: null,
      type: null,
      iban: null,
    });

    setTimeout(() => {
      handleUpdateAData();
    }, 0);
  };

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useAuthQuery({
    queryKey: ["paymentsData", pagination.pageIndex, pageSize],
    queryFn: () =>
      getPayments({
        params: filters,
        page: pagination.pageIndex + 1,
        perPage: pageSize,
      }),
  });

  const handleUpdateAData = () => {
    queryClient.invalidateQueries(["paymentsData"]);
    setPagination({ pageIndex: 0 });
  };

  useEffect(() => {
    try {
      const fetchAccountsFiltersData = async () => {
        const amountOfAcc = 1000;

        const response = await getExchangeRate(amountOfAcc);
        setAccountsFilterData(response?.data.data.accounts);
      };
      const fetchCardsFiltersData = async () => {
        const response = await getCardsOptions();
        setCardsFiltersData(response.cards);
      };

      fetchCardsFiltersData();
      fetchAccountsFiltersData();
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handlePageChange = useCallback((page) => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page - 1,
    }));
  }, []);

  const tabs = [{ title: "All payments" }];

  return (
    <div className={styles.paymentsContainer}>
      <h1>Payments</h1>

      <Paginations tabs={tabs} active={0} setActive={0} />

      <div className={styles.paymentsTableContainer}>
        <div className={styles.paymentsTableHeader}>
          <div className={styles.paymentsTableHeaderLeft}>
            <h3>ALL PAYMENTS</h3>
            <FiltersButton
              isActive={isActiveFilters}
              onClick={() =>
                isLoading || isFetching
                  ? ""
                  : setIsActiveFilters((prev) => !prev)
              }
            />
            <ReloadIcon
              onClick={handleUpdateAData}
              style={{
                animation: isFetching ? "spin 1s linear infinite" : "none",
              }}
            />
          </div>
          <ExcelButton
            onSubmit={() =>
              handleExcelDownload({
                fn: () => getExportPayments({ params: filters }),
                fileName: "cards",
              })
            }
          />
        </div>

        {isActiveFilters && (
          <div className={styles.filters}>
            <Group
              spacing="sm"
              mb="md"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minWidth: "100%",
              }}
            >
              <div
                className={styles.datePickerInput}
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <DatePicker
                  setFilters={setFilters}
                  setDateValue={setDateValue}
                  dateValue={dateValue}
                />
              </div>

              <Select
                label="Payments of accounts:"
                placeholder="Search by account"
                value={filters.accountId || ""}
                onChange={(selectedId) => {
                  const selectedAccount = accountsFiltersData?.find(
                    (item) => item.ID === selectedId
                  );
                  if (selectedAccount) {
                    setFilters((prev) => ({
                      ...prev,
                      accountId: selectedAccount.ID,
                      iban: selectedAccount.iban,
                    }));
                  } else {
                    setFilters((prev) => ({
                      ...prev,
                      accountId: null,
                      iban: null,
                    }));
                  }
                }}
                data={
                  accountsFiltersData?.map((item) => ({
                    value: item.ID,
                    label: `${item.name}, ${item.iban}, ${
                      item.balance === 0
                        ? item.balance
                        : item.balance?.toFixed(2)
                    } EUR`,
                  })) || []
                }
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
                className={styles.inputWidths}
              />

              <Select
                label="Payments of card:"
                value={filters.cardId || ""}
                onChange={(value) => {
                  const selectedCard = cardsFiltersData?.find(
                    (item) => item.cardId === value
                  );
                  if (selectedCard) {
                    handleFilterChange("cardId", selectedCard.cardId);
                  } else {
                    handleFilterChange("cardId", null);
                  }
                }}
                placeholder="Search by card"
                data={
                  cardsFiltersData?.map((item) => ({
                    value: item.cardId,
                    label: `${item.name}, ${item.iban}, ${
                      item.balance === 0 || item.balance === null
                        ? 0
                        : item.balance?.toFixed(2)
                    } EUR`,
                  })) || []
                }
                allowDeselect
                nothingFound="No options"
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
                className={styles.inputWidths}
              />
              <Select
                label="Transaction Type:"
                value={filters?.type}
                onChange={(value) => handleFilterChange("type", value)}
                placeholder="Debit or Credit"
                data={[
                  { value: "Debit", label: "Debit" },
                  { value: "Credit", label: "Credit" },
                ]}
                className={styles.inputWidths}
                styles={{
                  label: {
                    marginBottom: 8,
                  },
                  input: {
                    "&:focus-within": {
                      borderColor: "rgb(33, 160, 56)",
                      borderWidth: "1px",
                    },
                  },
                  item: {
                    "&[data-hovered]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                      borderRadius: "4px",
                    },
                    "&[data-selected]": {
                      background:
                        "linear-gradient(88.58deg, #83DA4E 2.42%, #21A038 51.09%, #54ACB8 95.68%) !important",
                      color: "#fff",
                    },
                  },
                }}
              />
            </Group>
            <div className={styles.filterButtons}>
              <button
                className={styles.applyFiltersButton}
                onClick={handleUpdateAData}
              >
                Apply filters
              </button>
              <button
                className={styles.clearFiltersButton}
                onClick={handleClearFilters}
              >
                Clear filters
              </button>
            </div>
          </div>
        )}

        <div className={styles.paymentsTable}>
          {isLoading || isFetching ? (
            <Skeleton className={styles.skeletonTable} isSpinner={true} />
          ) : data?.data.length ? (
            <Table
              striped
              highlightOnHover
              sx={{
                minWidth: "800px",
                tableLayout: "auto",
                overflow: "auto",
              }}
              onPaginationChange={(updater) => {
                setPagination((prev) => {
                  const newState =
                    typeof updater === "function" ? updater(prev) : updater;
                  return newState;
                });
              }}
              state={{
                pagination: {
                  pageIndex: pagination.pageIndex,
                  pageSize: pageSize,
                },
              }}
              pageCount={
                data?.meta?.last_page ??
                Math.ceil((data?.meta?.total ?? 0) / pageSize)
              }
              enablePagination={false}
            >
              <thead className={styles.paymentsHead}>
                <tr>
                  <th>DOCUMENT</th>
                  <th>DATE</th>
                  <th>CARD NUMBER</th>
                  <th>CARD NAME</th>
                  <th>STATUS</th>
                  <th>TRANSACTION AMOUNT</th>
                  <th>ACCOUNT AMOUNT</th>
                  <th>PAYMENT DETAILS</th>
                  <th>BALANCE</th>
                  <th>ACCOUNT #</th>
                </tr>
              </thead>
              <tbody className={styles.paymentsBody}>
                {data?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.ID}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {/* {new Date(item.dateTime).toLocaleDateString()}{" "}
                        {new Date(item.dateTime).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })} */}

                        <DateFormatter date={item.dateTime} />
                      </td>

                      <td className={styles.paymentsBodyCardNumber}>
                        <span className={styles.paymentsBodyCardNumberIcon}>
                          {getCorrectCardType(item.cardType)}
                        </span>

                        <Link
                          style={{ display: "flex" }}
                          to={`${PAGE_ROUTES.CARD_DETAILES.replace(
                            ":id",
                            item.cardId
                          )}`}
                        >
                          <CardNumber cardNum={item.cardNumber} />
                          <CustomTooltip
                            placement="bottom-start"
                            title="Click to see card details"
                            arrow
                          >
                            <span className={styles.paymentsBodyQuestionSpan}>
                              <QestionIcon />
                            </span>
                          </CustomTooltip>
                        </Link>
                      </td>
                      <td
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Link
                          className={styles.paymentsBodyCardNameSpan}
                          to={`${PAGE_ROUTES.CARD_DETAILES.replace(
                            ":id",
                            item.cardId
                          )}`}
                        >
                          {item.cardName}
                        </Link>
                      </td>
                      <td>
                        <StatusBadge status={item.status} />
                      </td>
                      <td
                        style={{
                          color: item.type === "Credit" ? "#21A038" : "black",
                        }}
                      >
                        {item.type === "Credit" ? "+" : "-"}{" "}
                        {item.merchantAmount} {item.accountCurrency}
                      </td>
                      <td
                        style={{
                          color: item.type === "Credit" ? "#21A038" : "black",
                        }}
                      >
                        {item.type === "Credit" ? "+" : "-"}
                        {item.accountAmount} €
                      </td>
                      <td className={styles.columnPaymentDetails}>
                        {item.details}
                      </td>
                      <td>€{item.balance}</td>
                      <td
                        className="secondaryText"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span className={styles.paymentsBodyAccountSpan}>
                          #{item.accountIban.slice(-6)}
                        </span>{" "}
                        {item.accountName}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p style={{ textAlign: "center", color: "#AAAAAA" }}>
              No records for display
            </p>
          )}
        </div>
        <Pagination
          value={pagination.pageIndex + 1}
          onChange={handlePageChange}
          total={
            data?.meta?.last_page ??
            Math.ceil((data?.meta?.total ?? 0) / pageSize)
          }
          position="right"
          withEdges
          icons={{
            first: <IconChevronLeftPipe size={16} />,
            last: <IconChevronRightPipe size={16} />,
          }}
        />
      </div>
    </div>
  );
};

export default Payments;
