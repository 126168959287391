import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { DateFormatter } from "../DateFormatter/DateFormatter";
import styles from "./styles.module.scss";

const NewsItem = ({ data }) => {
  const navigate = useNavigate();
  const isHTML = (text) => /<\/?[a-z][\s\S]*>/i.test(text);

  const removeFigcaption = (htmlString) => {
    return htmlString.replace(/<figcaption[^>]*>.*?<\/figcaption>/gi, "");
  };

  return (
    <>
      {data && Array.isArray(data) ? (
        data.map((news) => (
          <div
            key={news.id}
            className={styles.newsItem}
            onClick={() => navigate(`/news-article/${news.id}`)}
          >
            <div className={styles.newsItemLeft}>
              <div className={styles.newsItemText}>
                <div className={styles.containerTitle}>
                  <h2 className="semiHedline">{news.title} </h2>
                  <div className={styles.importantTitle}>Important news</div>
                </div>

                <div>
                  {isHTML(news.content) ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: removeFigcaption(news.content),
                      }}
                    />
                  ) : (
                    <p className="primaryText">{news.content}</p>
                  )}
                </div>
              </div>

              <p className={`${styles.newsItemDate} secondaryText`}>
                <DateFormatter
                  date={news.updated_at}
                  withTime={false}
                  className={styles.dateTekst}
                />
                <Button className={styles.newsItemButtonMobile}>
                  Read more
                </Button>
              </p>
            </div>
            <Button className={styles.newsItemButton}>Read more</Button>
          </div>
        ))
      ) : (
        <p>No news available</p>
      )}
    </>
  );
};

export default NewsItem;
